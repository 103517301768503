import { Link, Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';

import Header from './Header';
import Footer from './Footer';

import { getLanguageCode } from '../i18n';
import { useTranslation } from 'react-i18next';
import arrow from '../resources/images/r-arrow.png';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

const Banner = styled.h3`
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    flex-wrap: nowrap;
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        /* flex-basis: max-content; */
        /* flex-grow: 1; */
        /* flex-shrink: 1; */

        &:nth-of-type(n) {
            background-color: #532081;
            &:last-of-type {
                background-color: #743FA3;
            }
        }
        &:nth-of-type(2n) {
            background-color: #743FA3;
        }
        span {
            margin: 0.5rem;
            text-align: center;
            word-break: keep-all;
            img {
                display: inline-block;
                height: 100%;
                vertical-align: baseline;
            }
        }
    }
    @media (max-width: 768px) {
        a {
            line-height: 1.6;
        }
    }
`;

// 공고
// new Date(2023,9-1,18).getTime() === 1694962800000  한국시간 2023-09-18 공고 자동 내리기
// 기획: 공고 최대 동시 2개만 노출.
// 시간으로 자동 내리기 기능 본격적으로 추가하자.
// new Date(2023,9-1,16).toISOString()
const originalNotifys = ([
    {
        title: '제3자배정 유상증자 신주발행 공고',
        start: new Date('2024-10-16T09:00:00.000Z'),// 10-13 18:00
        end:   new Date('2024-11-01T03:00:00.000Z'),// 11-01 12:00
        link: '/ko/Notice20241017'
    },
    {
        title: '임시주주총회 소집 공고',
        start: new Date('2024-10-16T09:00:00.000Z'),// 9-16
        end: new Date('2024-11-07T15:00:00.000Z'),// 9-27 12:00
        link: '/ko/Notice20241024'
    },
]).slice(0, 2);
const notifys = originalNotifys.filter((item) => {
    const now = Date.now();
    const start = new Date(item.start).getTime();
    const end = new Date(item.end).getTime();
    // console.log(start, now, end)
    return start <= now && now <= end;
});

const isUseNotify = notifys.length > 0;


const Layout = () => {
    const { i18n } = useTranslation();
    const { pathname } = useLocation();
    const [mainClass, setMainClass] = useState('');

    useEffect(() => {
        let className = pathname === `/${i18n.language}` || pathname === `/${i18n.language}/` ? 'main' : '';
        if (pathname.indexOf("Careers") > 0) {
            className = className + " bg_fe";
        }
        setMainClass(className);
    }, [pathname]);


    const notify = isUseNotify && i18n.language === "ko" ? (<div className="notice_wrap">
        <Banner className="notice_text">
            {
                notifys.map((item, index) => {
                    return (<Link to={item.link} key={`notify-${index}`} target={"_blank"}>
                        <span>
                            {item.title} <img src={arrow} className="arrow-icon" />
                        </span>
                    </Link>);
                })
            }
        </Banner>
    </div>) : (<></>);

    return (

        <>
            {notify}

            <div id="wrap" className={mainClass}>

                <Header {...{isUseNotify}} />
                <Outlet />
                {pathname !== '/Contact' && (<aside className="floating_btn_wrap visible_sm">
                    <Link
                        to={`Contact`}
                        className="floating_btn"
                    >
                        <span className="btn_inner">
                            <span className="icon"></span>
                        </span>
                    </Link>
                </aside>)}

                <Footer />
            </div>
        </>
    );
};

export default Layout;
