import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import ContactLayer from './ContactLayer';


import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import bg_contact from '../resources/images/bg_contact.png';

function Contact() {
    const { t, i18n, language } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    const ContactLayerRef = useRef();
    const openModal = () => {
        // console.log(`/static/file/${pt('company_profile_file')}`);
        ContactLayerRef.current.openModal();
    };
    
    useEffect(() => {
        $(window).scrollTop(0);
    }, []);

    // 다운로드 api 호출
    function callFileDownload() {
        let defaultFile = '2bytes_Company Introduction_2023_EN.pdf';
        let file = pt('company_profile_file');
        

        const download = async (filename) => {
            const baseUrl = process.env.REACT_APP_PUBLIC_URL || '';
            const url = `${baseUrl}static/file/${encodeURIComponent(filename)}`;
            try {
                const res = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/pdf',
                    }
                });
                
                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }
        
                // 응답을 arrayBuffer로 받아서 처리
                const arrayBuffer = await res.arrayBuffer();
                const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                
                // 다운로드 링크 생성
                const blobUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = blobUrl;
                a.download = filename;
                
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                
                // 메모리 정리
                setTimeout(() => {
                    URL.revokeObjectURL(blobUrl);
                }, 100);
                
                return true;
            } catch (error) {
                console.error('다운로드 실패:', error);
                throw error;
            }
        };
        // 실패 시, 영어로 한번만 더 시도한다.
        download(file).catch(() => download(defaultFile));
    }

    const downloadCompanyIntroduction = () => {
        try {
            callFileDownload();
        } catch (e) { }
    };

    return (
        <>
            <div id="container" className="cont_contact">
                <section className="section_top">
                    <div className="page_cover">
                        <div className="inner">
                            <h2 className="cont_title">
                                {pt('Contact_title')}
                                {/* Contact */}
                            </h2>
                            <p className="desc"><span>{pt('Contact_1')}</span></p>
                        </div>
                    </div>

                    <div className="tab_wrap text_tab">
                        <div className="tab_box">
                            <ul className="tab_list clear">
                                <li className="col1 on">
                                    <strong className="text" style={{ color: "#1dd1d3" }}>
                                        {pt('Contact_title')}
                                        {/* Contact */}
                                    </strong>

                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <div className="section_content_wrap">
                    <div className="section_content_box">
                        <section className="section_discript">
                            <strong className="title">
                                <span>
                                    {pt('Contact_3')}
                                    {/* 2bytes가 여러분과 함께 하겠습니다! */}
                                </span>
                            </strong>
                            <div className="desc purple">
                                {pt('Contact_5')}
                                {/* 2bytes를 믿고 맡겨 주실 여러분들을 환영합니다!
                                <br />
                                게임 개발 단계에 따라 제공 가능한 서비스가 다양합니다.
                                <br />
                                필요한 각종 문의/업무의뢰를 아래 메일로 보내주세요.
                                <br />
                                빠르게 회신 드리겠습니다. */}
                            </div>
                        </section>

                        <section className="section_contact">
                            <div className="contactUs">
                                <div className="box">
                                    <p style={{ marginBottom: '8px' }}>
                                        {pt('Contact_6')}
                                        {/* 문의메일 */}
                                        {' : '}
                                        <a
                                            href="mailto:contact@2bytescorp.com"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            contact@2bytescorp.com
                                        </a>
                                    </p>
                                    <p style={{ marginBottom: '8px' }}>
                                        {pt('Contact_7')}
                                        {/* 업무문의 */}
                                        {' : '}
                                        <a href="tel:031-423-0758">
                                            {t('Contact_tel')}
                                            {/* 031-423-0758 */}
                                        </a>
                                    </p>
                                    <p>
                                        {pt('Contact_8')}
                                        {/* 찾아오시는 곳 */}
                                        {' : '}
                                        <a href="http://kko.to/ABcEVtiqIS)" target="_blank" rel="noreferrer">
                                            {pt('Contact_9')}
                                            {/* 경기도 안양시 동안구 부림로 170번길 38. */}
                                        </a> </p>
                                </div>

                                <div className="btn_area">
                                    <button
                                        type="button"
                                        className="btn_download"
                                        onClick={downloadCompanyIntroduction}
                                    >
                                        {pt('Contact_10')}
                                        {/* 회사소개서 다운로드 <small>(PDF, 2M)</small> */}
                                    </button>
                                </div>
                                <img src={bg_contact} alt="bg_contact image" />
                            </div>
                        </section>
                    </div> {/* section_content_box end */}
                    <div className="content_form">
                        <ContactLayer />
                    </div>
                </div> {/* section_content_wrap end */}
                <section className="section_bottom contact">
                    <div className="inner d_table">
                        <div className="d_cell text_white">
                            {pt('Contact_11')}
                            {/* 채용 관련 문의처를 찾으세요?
                            <br />
                            채용 홈페이지 게시판을 이용해주시기 바랍니다. */}
                        </div>
                        <div className="d_cell btn_area">
                            <Link
                                to={`/${i18n.language}/Careers/Life`}
                                className="btn_basic btn_xl btn_pop_open"
                                rel="noreferrer"
                            >
                                {pt('Contact_12')}
                                {/* 채용 알아보기 */}
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Contact;
