import React, { useEffect } from 'react';

function Notice() {

    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);

    return (
        <div id="container" className="cont_nt">
            <section className="section">
                <div className="inner">
                    <div className="title-box">
                        <h2>
                            제4기 정기주주총회 소집 공고
                        </h2>
                        <p>
                            주주님의 건승과 댁내의 평안을 기원합니다. 
                            <br />
                            당사는 정관 제19조 및 제21조에 근거하여 제4기 정기주주총회를 아래와 같이 소집 공고하오니 참석하여 주시기 바랍니다.<br /><br />
                        </p>
                    </div>

                    <div className="dl_box">

                        <dl>
                            <dt>
                                1. 일 시 : 2024년 3월 27일(수) 오전 11시
                            </dt>
                        </dl>

                        <dl>
                            <dt>
                                2. 장 소 : 경기도 안양시 동안구 시민대로 327번길 24, 본사 8층 회의실
                            </dt>

                        </dl>
                        <dl>
                            <dt>
                                3. 회의목적사항
                            </dt>
                            <dd>
                                <dl>
                                    <dt>
                                        1) 보고사항
                                    </dt>
                                    <dd >
                                        : 영업보고
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        2) 부의안건
                                    </dt>
                                    <dd >
                                        제1호 의안 : 제4기(2023.01.01~2023.12.31) 재무제표 승인의 건, 이익잉여금 처분계산서 승인의 건
                                        <br />
                                         제2호 의안 : 이사 선임의 건
                                         <br />
                                         제3호 의안 : 이사 보수한도 승인의 건
                                         <br />
                                         제4호 의안 : 주식매수선택권 부여의 건
                                    </dd>
                                </dl>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                4. 경영참고사항 비치

                            </dt>
                            <dd >
                                상법 제542조의 4에 의거 경영참고사항을 당사에 비치하오니 참고하시기 바랍니다. 
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                5. 의결권 대리행사 관련 사항
                            </dt>
                            <dd style={{ marginLeft: "30px" }}>
                                <span className='not-indent'>1)</span>
                                금번 당사의 주주총회는 주주님께서 참석하시어 의결권을 직접 행사하시거나 또는 위임장에 의거 의결권을 간접 행사하실 수 있습니다. 위임장을 작성하신 후 회사로 우편 송부하여 행사하실 수 있습니다.

                                <span>
                                    · 위임장 보내실 곳: 경기도 안양시 동안구 시민대로 327번길 24, 6층 투바이트㈜ 재무팀 주주총회 담당자 앞 (우편번호 14055)

                                </span>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                6. 주주총회 참석시 준비물
                            </dt>
                            <dd style={{ marginLeft: "30px" }}>
                                <span className='not-indent'>1)</span> 직접행사 :  신분증 <br />
                                <span className='not-indent'>2)</span> 대리행사 : 위임장(주주와 대리인의 인적사항 기재, 인감 날인), 주주의 인감증명서 1부, 대리인의 신분증
                            </dd>
                        </dl>
                        <div >
                            <p className="footer-text">
                                2024년 03월 11일
                                <br />
                                <br />
                                투바이트 주식회사<br />
                                경기도 안양시 동안구 시민대로 327번길 24, 6층, 7층, 8층(관양동)<br />
                                대표이사 함영철<br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Notice;
