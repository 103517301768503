/** @jsxImportSource @emotion/react */
import {
    useState,
    useMemo,
    useCallback,
    useRef,
    useEffect,
    forwardRef,
    useImperativeHandle
} from 'react';
import { css } from '@emotion/react';
import StepCss from './StepCss';

// const validateUrl = (url) => /^https?:\/\/.{4,}/.test(url);

const StepPortfolio = forwardRef((props, ref) => {
    //   console.log(props);
    const [portfolioValid, setPortfolioValid] = useState(true);
    const [portfolio, setPortfolio] = useState('');
    const [portfolioError, setPortfolioError] = useState('');
    const fileInputRef = useRef();
    const [portfolioUrl, setPortfolioUrl] = useState(props.url ?? '');
    const [portfolioUrlValid, setPortfolioUrlValid] = useState(true);
    const [portfolioUrlError, setPortfolioUrlError] = useState('');
    const [file, setFile] = useState();
    const portfolioFilename = useMemo(() => {
        let filename = portfolio ?? '';
        filename = filename.match(/[^\\]+?$/);
        return filename ? filename[0] : '';
    }, [portfolio]);
    const testPortfolio = useCallback(() => {
        let file = fileInputRef.current.files[0];
        let b = false;
        setFile(file);
        b = /\.(pdf|zip)$/i.test(portfolio);
        setPortfolioValid(b);
        setPortfolioError(b ? '' : '*.pdf, .zip파일로 첨부하세요.');
        if (b === false) return b;
        b = file.size <= 30 * 1024 * 1024;
        setPortfolioValid(b);
        setPortfolioError(b ? '' : '*30MB 이하 파일로 첨부하세요.');
        return b;
    }, [portfolio]);
    const testPortfolioUrl = useCallback(() => {
        let url = portfolioUrl.trim();
        if (url && !/^https?:\/\//.test(url)) {
            // setPortfolioUrlValid(false);
            // setPortfolioUrlError('*url을 수정해 드렸으니 확인하세요.');
            url = 'https://' + url;
            setPortfolioUrl(url);
        }
        
        let expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        let regex = new RegExp(expression);

        let b = regex.test(url);
        setPortfolioUrlValid(b);
        setPortfolioUrlError(b ? '' : '*url을 정확하게 입력하세요.');
        return b;
    }, [portfolioUrl]);
    const test = useCallback(() => {
        let skipable = (Boolean(props.skipable) && portfolio === '' && portfolioUrl === '');
        // console.log('--------', skipable ? 'skip' : 'no skip', Boolean(props.skipable), portfolio, portfolioUrl);

        if (skipable) {
            if (portfolio === '' && portfolioUrl === '') {
                setPortfolioError('');
                setPortfolioUrlError('');
                return true;
            }
        } else {
            if (portfolio === '' && portfolioUrl === '') {
                testPortfolio();
                testPortfolioUrl();
                return false;
            }
        }
        let b = true;
        if (portfolio !== '') b = testPortfolio() && b;
        else setPortfolioError('');

        if (portfolioUrl !== '') b = testPortfolioUrl() && b;
        else setPortfolioUrlError('');

        return b;

    }, [portfolio, portfolioUrl]);
    const openFile = useCallback((e) => {
        fileInputRef.current.click();
    }, []);
    const changePortfolio = useCallback((e) => {
        setPortfolio(e.target.value);
        setFile(e.target.files[0])
    }, []);
    const changePortfolioUrl = useCallback((e) => {
        setPortfolioUrl(e.target.value);
    }, []);

    useEffect(() => {
        if (!portfolio) {
            setPortfolioError('');
        }
    }, [portfolio]);
    useEffect(() => {
        if (!portfolioUrl) {
            setPortfolioUrlError('');
        }
    }, [portfolioUrl]);

    useImperativeHandle(ref, () => ({
        portfolio,
        portfolioFilename,
        setPortfolio,
        portfolioValid,
        portfolioUrlValid,
        portfolioUrl,
        setPortfolioUrl,
        file,
        test
    }));

    return (
        <div css={StepCss.root}>
            <div css={StepCss.titleRoot}>
                <div css={StepCss.title}>
                    {props.title ?? '포트폴리오를 첨부해주세요.'}
                    {props.skipable ? '(선택)' : ''}
                </div>
                <div css={StepCss.titleDesc}>{props.desc}</div>
            </div>
            <div css={StepCss.fromBox}>
                <div css={StepCss.content2ea}>
                    <div css={StepCss.inputBox}>
                        <input
                            ref={fileInputRef}
                            hidden
                            type="file"
                            onChange={changePortfolio}
                        />
                        <input
                            readOnly
                            css={[StepCss.input, StepCss.file]}
                            type="text"
                            value={portfolioFilename}
                            onClick={openFile}
                            placeholder={props.placeholder ?? "포트폴리오(.pdf/.zip) 첨부"}
                        />
                        <div css={StepCss.error}>
                            {portfolioError}
                        </div>
                    </div>
                    <div css={StepCss.or}>or</div>
                    <div css={StepCss.inputBox}>
                        <input
                            css={StepCss.input}
                            type="text"
                            value={portfolioUrl}
                            onChange={changePortfolioUrl}
                            placeholder="포트폴리오 url"
                        />
                        <div css={StepCss.error}>
                            {portfolioUrlError}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
});

export default StepPortfolio;
