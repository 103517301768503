/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const width2ea = '80rem'

export default {
    root: css`
    /* min-height: 31rem; */
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  `,

   fromBox: css`
        width: 100%;
        padding: 5rem 0;
        
        @media (max-width: 992px) {
            padding: 5rem 0 7rem;
        }
  `,
  inputBox: css`
    width: 100%;
    max-width: 35rem;
    margin: 0 auto;
    @media (max-width: 1320px) {
        max-width: 35rem;
        font-size: 3rem;
    
    }
    @media (max-width: 992px) {
        font-size: 2.4rem;
        
        max-width: 35rem;
    }
    `,

    title: css`
        font-weight: 400;
        line-height: 4.2rem;
        color: #444444 !important;
        font-size: 3rem;
        @media (max-width: 1320px) {
            font-size: 3rem;
        }
        @media (max-width: 992px) {
            font-size: 2.4rem;
            
        }
    
  `,
  

    titleDesc: css`
    position: absolute;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.6rem;
    width: 100%;
    left: 0;
    color: rgba(0, 0, 0, 0.4) !important;
  `,

    titleDesc2: css`
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3.6rem;
    text-align: center;
    color: #444444;
  `,

    content2ea: css`
    display: flex;
    flex-direction: row;
    
    @media (max-width: ${width2ea}) {
      flex-direction: column;
      gap: 4rem;
    }
  `,

    input: css`
    width: 100%;
    height: 5.2rem;
    color: #444444;
    background: #fafafb;
    
    text-align: center;
    line-height: 3rem;
    border-radius: 0;
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
    border: 1px solid #d5d3e4;
    
    font-size: 1.8rem;
    width: 100%;

    @media (max-width: 36rem) {
        font-size: 1.6rem;
        width: 100%
    }
  `,

    select: css`
    width: 100%;
    height: 5.2rem;
    text-align: center;
    line-height: 30px;
    margin: 0.5rem;
    border-radius: 0;
    border: 1px solid #d5d3e4;
    box-sizing: border-box;
    background-color: white;
    font-size: 1.8rem;
    &:focus {
        outline:  none;
        border: 2px solid #743fa3 !important;
    }
    &:hover{
        border: 2px solid #743fa3 ;
    }
   
    .MuiOutlinedInput-notchedOutline{
        border: 0;
    }
    @media (max-width: 36rem) {
        font-size: 1.6rem;
        width: calc(100% - 2rem * 2);
    }
  `,

    option: css`
    height: 50px;
    background-color: white;
    justify-content: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #444444;
  `,

    paper: css`
    background: #FFFFFF;
    border: 1px solid #D5D3E4;
    /* & li {
      paddingTop:12;
      paddingBottom:12;
      paddingRight:8,
      paddingLeft:8,
    },
    "& li:hover":{
      background: blue[50]
    },
    "& li.Mui-selected":{
      color:'black',
      background: 'white'
    },
    "& li.Mui-selected:hover":{
      background: blue[50]
    } */
`,

    file: css`
    cursor: pointer;
  `,

    or: css`
    color: #444;
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin: 21px;
    @media (max-width: ${width2ea}) {
        margin: -2rem;
    }
  `,listBox: css`
    width: 100%;
    max-width: 45rem;
    margin: 0 auto;
    @media (max-width: 1320px) {
        max-width: 45rem;
        font-size: 3rem;
    
    }
    @media (max-width: 992px) {
        font-size: 2.4rem;
        
        max-width: 100%;
    }
    `,

    list: css`
    padding: 18px;
    width: 100%;
    background: #fafafb;
    border: 1px solid #d5d3e4;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    align-items: center;
    color: #444;
    margin: 0 auto 1.6rem;
    
  `,

    checkText: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    color: #444444;
  `,

    checkText2: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    color: #000;
  `,

    errorColor: css`
    color: #B3261E!important;
    `,

    checkAnchor: css`
    font-weight: 700;
    text-decoration: underline !important;
  `,

    error: css`
    position: absolute;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #b3261e;
  `,

    displayNone: css`
        display: none;
    `,
    finshedTitle: css`
        font-weight: 700;
        line-height: 4.2rem;
        color: #743FA3 !important;
        font-size: 3rem;
        @media (max-width: 1320px) {
            font-size: 3rem;
        }
        @media (max-width: 992px) {
            font-size: 2.4rem;
            
        }
 
    `,
    finshedTitle2: css`
        font-weight: 400;
        line-height: 4.2rem;
        color: #444 !important;
        font-size: 2.2rem;
        padding-bottom: 16rem;
        @media (max-width: 1320px) {
            font-size: 2.2rem;
            padding-bottom: 18rem;
        }
        @media (max-width: 992px) {
            font-size: 1.4rem;
            padding-bottom: 20rem;
            
        }

    `,

};
