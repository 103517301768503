import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../resources/images/privacy_arrow_right.svg';

function Privacy() {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    //sdf
    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);
    return (
        <div id="container" className="cont_pv">
            <section className="section">
                <div className="inner">
                    <div className="title-box">
                        <h2>
                            {pt('Privacy_1')}
                            {/* 개인정보 처리방침 */}
                            <span> {pt('Privacy_2')}</span>
                        </h2>
                        <p>{pt('Privacy_3')}</p>
                    </div>

                    <div className="dl_box">
                        <dl>
                            <dt>
                                {pt('Privacy_4')}

                            </dt>
                            <dd>
                                {pt('Privacy_5')}
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                {pt('Privacy_6')}

                            </dt>
                            <dd>
                                {pt('Privacy_7')}
                                <span>{pt('Privacy_8')}</span>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                {pt('Privacy_9')}

                            </dt>
                            <dd>
                                {pt('Privacy_10')}
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                {pt('Privacy_11')}
                            </dt>
                            <dd >
                                <strong>{pt('Privacy_12')}</strong>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                {pt('Privacy_13')}


                            </dt>
                            <dd>
                                {pt('Privacy_14')}

                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                {pt('Privacy_15')}
                            </dt>
                            <dd>
                                {pt('Privacy_16')}
                                <span className='PrivacyChangeButtonPosirionRef'>{pt('Privacy_17')}</span>

                            </dd>
                        </dl>

                    </div>
                    <Link to={`/${i18n.language}/Privacy`} className='PrivacyChangeButton' >{pt('Privacy_18')}<Arrow /></Link>
                </div>
            </section>
        </div>
    );
}

export default Privacy;
