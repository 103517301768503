import axios from "axios"

export default function callSlack(url, params) {
  const options = {
    headers: { 'Content-type': 'application/x-www-form-urlencoded' },
  }
  return axios.post(url, params, options).catch((error) => {
    if (error.response) {
      // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
      console.debug(error.response.data)
      console.debug(error.response.status)
      console.debug(error.response.headers)
    } else if (error.request) {
      // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
      console.debug(error.request)
    } else {
      // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
      console.debug('Error', error.message)
    }
    console.debug('[Slack Error]', error.message)
  })
}
