/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ill_cm from '../resources/images/ill_cm.png?url';


export default function Why2Bytes() {

    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        $('.open_modal').YouTubePopUp();
    }, []);


    const pageLink = (page) => {
        const currentPath = pathname.trim().split('/')[3];
        console.log(page, currentPath);
        if (page === currentPath) {
            $(window).scrollTop(0);
        } else if (page === "Localization") {
            navigate(`/${i18n.language}/Services/Localization`);
        }
        else if (page === "QA") {
            navigate(`/${i18n.language}/Services/QA`);
        }
        else if (page === "PlayerSupport") {
            navigate(`/${i18n.language}/Services/PlayerSupport`);
        }
        else if (page === "ContentMarketing") {
            navigate(`/${i18n.language}/Services/ContentMarketing`);
        }
        else if (page === "GameSound") {
                    navigate(`/${i18n.language}/Services/GameSound`);
        }
        else if (page === "CoPublishing") {
            navigate(`/${i18n.language}/Services/CoPublishing`);
        }
    };


    return (
        <section className="section_why">
            <div className="inner">
                <div className="h_title_box">
                    <h3 className="h_title">
                        {pt('Why2Bytes_1')}
                        {/* Why 2bytes? */}
                    </h3>
                </div>

                {/* 여기부터 수정 필요*/}
                <div className="cont_box_wrap clear" >

                    <div className="box col1 col1_sm ll">
                        <div className="box_in ll">
                            <p className="team_name">
                                {pt('Footer_5')}
                                {/* Localization / LQA */}
                            </p>

                            <div className="cont">
                                <strong className="title">
                                    {pt('Why2Bytes_3')}
                                </strong>
                                <div className="desc under_text">
                                    <p className="services_desc">
                                        {pt('Why2Bytes_4')}
                                    </p>
                                </div>
                            </div>

                            <div className="manager_talk">
                                <Box component="p" className="trans text_primary" sx={{
                                    fontSize: '3rem',
                                    marginRight: '-2.5rem',
                                    marinTop: '-3rem',
                                    whiteSpace: 'nowrap'
                                }}>
                                    <span className='under_box'>{pt('Why2Bytes_6')}</span>
                                    {/* <strong className="ko">한글</strong><strong className="num">20<em className="plus">+</em></strong>언어 */}
                                </Box>

                                <button onClick={() => pageLink("Localization")} className="btn_basic">
                                    {pt('Why2Bytes_5')}
                                    {/* 자세히 보기 */}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="box col1 col1_sm ps">
                        <div className="box_in ps">
                            <p className="team_name">
                                {pt('Footer_7')}
                                {/* Game Operation */}
                            </p>

                            <div className="cont">
                                <strong className="title">
                                    {pt('Why2Bytes_15')}
                                </strong>
                                <div className="desc">
                                    <p className="services_desc">
                                        {pt('Why2Bytes_16')}
                                    </p>
                                </div>
                            </div>

                            <div className="manager_talk">
                                <button onClick={() => pageLink("PlayerSupport")} className="btn_basic">
                                    {pt('Why2Bytes_5')}
                                    {/* 자세히 보기 */}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="box col1 col1_sm cm">
                        <div className="box_in cm">
                            <p className="team_name">
                                {pt('Footer_15')}
                            </p>

                            <div className="cont">
                                <strong className="title">
                                    {pt('Why2Bytes_18')}
                                </strong>
                                <div className="desc">
                                    <p className="services_desc">
                                        {pt('Why2Bytes_19')}
                                    </p>
                                </div>
                            </div>

                            <div className="manager_talk">
                                <button onClick={() => pageLink("ContentMarketing")} className="btn_basic">
                                    {pt('Why2Bytes_5')}
                                    {/* 자세히 보기 */}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="box col1 col1_sm qa">
                        <div className="box_in qa">
                            <p className="team_name">
                                {pt('Footer_6')}
                                {/* Quality Assurance */}
                            </p>

                            <div className="cont">
                                <strong className="title">
                                    {pt('Why2Bytes_10')}
                                </strong>
                                <div className="desc">
                                    <p className="services_desc">
                                        {pt('Why2Bytes_11')}
                                    </p>
                                </div>
                            </div>

                            <div className="manager_talk">
                                <button onClick={() => pageLink("QA")} className="btn_basic">
                                    {pt('Why2Bytes_5')}
                                </button>
                            </div>
                        </div>
                    </div>

                     <div className="box col1 col1_sm gs">
                        <div className="box_in gs">
                            <p className="team_name">
                                {pt('Footer_16')}
                                {/* Game Sound/Voice-Over */}
                            </p>

                            <div className="cont">
                                <strong className="title">
                                    {pt('Why2Bytes_21')}
                                </strong>
                                <div className="desc">
                                    <p className="services_desc">
                                        {pt('Why2Bytes_22')}
                                    </p>
                                </div>
                            </div>

                            <div className="manager_talk">
                                <button onClick={() => pageLink("GameSound")} className="btn_basic">
                                    {pt('Why2Bytes_5')}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="box col1 col1_sm cp">
                        <div className="box_in cp">
                            <p className="team_name">
                                {pt('Footer_17')}
                                {/* Co-Publishing */}
                            </p>

                            <div className="cont">
                                <strong className="title">
                                    {pt('Why2Bytes_23')}
                                </strong>
                                <div className="desc">
                                    <p className="services_desc">
                                        {pt('Why2Bytes_24')}
                                    </p>
                                </div>
                            </div>

                            <div className="manager_talk">
                                <button onClick={() => pageLink("CoPublishing")} className="btn_basic">
                                    {pt('Why2Bytes_5')}
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}
