import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import parse from 'html-react-parser';

// 언어팩
import en from './en.json';
import ko from './ko.json';
import ja from './jpn.json';
import zh from './ch_01.json';


// 설정
i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    ns: ['translationsNS'],
    defaultNS: 'translationsNS',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: { translationsNS: en },
        ko: { translationsNS: ko },
        ja: { translationsNS: ja },
        "zh-CN": { translationsNS: zh }
    },
});

// css파일에서 사용된 언어 클래스 정의를 LANGUAGES에 수록하고 향후 개발에 맞게 추가를 진행합니다.
export const LANGUAGES = Object.freeze(['ko', 'en', 'ja', 'zh']);
export const KEY = 'user-selected-language';//localStorage에서 사용하는 키 이름
export const getLanguageText = (lang) => {
    const s = {
        ko: '한국어',
        en: 'ENGLISH',
        ja: '日本語',
        "zh-CN": '简体中文',
    };
    return s[lang];
};// LANGUAGES를 근거로 검색되는 언어 이름

export const t = (v) => parse(i18n.t(v));


// // 기타추가
// (() => {
//     // 1
//     Object.defineProperty(window, 'userSelectedLanguage', {
//         get() {
//             return localStorage.getItem(KEY) || 'ko';
//         },
//         set(v) {
//             LANGUAGES.includes(v) && localStorage.setItem(KEY, v);
//         }
//     });
//     // 2
//     let d = window.userSelectedLanguage;
//     if (d) {
//         changeLanguage(d);
//         console.log('window.userSelectedLanguage', d);
//     }
// })();

// 내보내기

export default i18n;