/** @jsxImportSource @emotion/react */
import {
  useState,
  useMemo,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react';
import StepCss from './StepCss';

function getSavedApplicantName() {
    let str = careers?.senderParams?.applicantName
    if(str) {
        let index = str.indexOf('(')
        if(index > 0) {
            return {
                name: str.slice(0, index).trim(),
                englishName: str.slice(index+1, -1),
            }
        }
    }
}

const StepEnglishName = forwardRef((props, ref) => {
  const [nameValid, setNameValid] = useState(true);
  const [name, setName] = useState(props.name ?? '');
  const [nameError, setNameError] = useState('');
  const [englishNameValid, setEnglishNameValid] = useState(true);
  const [englishName, setEnglishName] = useState(props.englishName ?? getSavedApplicantName()?.englishName ?? '');
  const [englishNameError, setEnglishNameError] = useState('');
  const allName = useMemo(() => `${name} (${englishName})`);
  const testName = useCallback(() => {
    let b = name.length > 0;
    setNameValid(b);
    setNameError(b ? '' : '*한글로 이름을 입력하세요.');
    if (b === false) return b;
    b = /^[ 가-힣]{1,20}$/.test(name);
    setNameValid(b);
    setNameError(b ? '' : '*한글로 이름을 입력하세요.');
    return b;
  }, [name]);
  const testEnglishName = useCallback(() => {
    let b = englishName.length > 0;
    setEnglishNameValid(b);
    setEnglishNameError(b ? '' : '*영문자로 이름을 입력하세요.');
    if (b === false) return b;
    b = /^[- 'a-z0-9]+$/i.test(englishName);
    setEnglishNameValid(b);
    setEnglishNameError(b ? '' : '*영문자로 이름을 정확하게 입력하세요.');
    return b;
  }, [englishName]);
  const test = useCallback(() => {
    let b1 = testName();
    let b2 = testEnglishName();
    return b1 && b2;
  }, [name, englishName]);
  const changeName = useCallback((e) => {
    setName(e.target.value);
  }, []);
  const changeEnglishName = useCallback((e) => {
    setEnglishName(e.target.value);
  }, []);

  useImperativeHandle(ref, () => ({
    name,
    setName,
    englishName,
    setEnglishName,
    allName,
    test
  }));

  return (
    <div css={StepCss.root}>
      <div css={StepCss.titleRoot}>
        <div css={StepCss.title}>{props.title ?? '이름을 작성하세요.'}</div>
        <div css={StepCss.titleDesc}>{props.desc}</div>
      </div>
      <div css={StepCss.fromBox}>
        <div css={StepCss.content2ea}>
            <div css={StepCss.inputBox}>
            <input
                css={StepCss.input}
                type="text"
                value={name}
                onChange={changeName}
                placeholder="한글 이름"
            />
            <div css={StepCss.error}>{nameError}</div>
            </div>
            <div css={StepCss.inputBox}>
            <input
                css={StepCss.input}
                type="text"
                value={englishName}
                onChange={changeEnglishName}
                placeholder="영어 이름"
            />
            <div css={StepCss.error}>{englishNameError}</div>
            </div>
        </div>

      </div>
      
    </div>
  );
});

export default StepEnglishName;
