/** @jsxImportSource @emotion/react */
import {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
    forwardRef,
    useImperativeHandle,
    memo,
} from 'react';
import { css } from '@emotion/react';
import StepCss from './StepCss';

const validateUrl = (url) => /^https?:\/\/.{4,}/.test(url);

const StepResume = memo(forwardRef((props, ref) => {
    const [fileValid, setFileValid] = useState(true);
    const [filenameFull, setFilenameFull] = useState(props.name ?? '');
    const [fileError, setFileError] = useState('');
    const [file, setFile] = useState();
    const fileInputRef = useRef();
    const filename = useMemo(() => {
        let n = filenameFull ?? '';
        n = n.match(/[^\\]+?$/);
        return n ? n[0] : '';
    }, [filenameFull]);
    const testFile = useCallback(() => {
        let file = fileInputRef.current.files[0];
        // console.log(file);
        let b = false;
        b = /\.(pdf|docx|doc)$/i.test(filenameFull);
        setFileValid(b);
        // setFileError(b ? '' : '*파일을 *.pdf,*.docx,*.doc로 첨부하세요.');
        setFileError(b ? '' : '*3MB 이하 첨부 / 확장자를 확인하세요.');
        if (b === false) return b;
        b = file.size <= 3 * 1024 * 1024;
        setFileValid(b);
        setFileError(b ? '' : '*3MB 이하 첨부 / 확장자를 확인하세요.');
        return b;
    }, [filenameFull]);
    const test = useCallback(() => {
        return testFile();
    }, [filenameFull]);
    const openFile = useCallback((e) => {
        fileInputRef.current.click();
    }, []);
    const changePortfolio = useCallback((e) => {
        setFilenameFull(e.target.value);
        setFile(e.target.files[0]);
    }, []);

    useEffect(() => {
        let _file = careers?.senderParams?.myPortfolioFileURL;
        if (_file instanceof File) {
            setFile(_file);
        }
    }, []);

    useImperativeHandle(ref, () => ({
        filenameFull,
        filename,
        fileValid,
        file,
        test
    }));

    return (
        <div css={StepCss.root}>
            <div css={StepCss.titleRoot}>
                <div css={StepCss.title}>
                    {props.title ??
                        '자기소개서, 경력기술서를 포함한 이력서를 첨부해 주세요.'}
                </div>
                <div css={StepCss.titleDesc}>{props.desc}</div>
            </div>
            <div css={StepCss.fromBox}>
                <div css={StepCss.content2ea}>
                    <div css={StepCss.inputBox}>
                        <input
                            ref={fileInputRef}
                            hidden
                            type="file"
                            onChange={changePortfolio}
                        />
                        <input
                            readOnly
                            css={[StepCss.input, StepCss.file]}
                            type="text"
                            value={filename}
                            onClick={openFile}
                            placeholder={props.placeholder ?? '자기소개서(pdf 또는 word 파일) 첨부'}
                        />
                        <div css={StepCss.error}>{fileError}</div>
                    </div>
                </div>

            </div>
           
        </div>
    );
}));

export default StepResume;
