import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const useTracking = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (!window.gtag) return;

        window.gtag("config", process.env.REACT_APP_G_TAG_ID, { page_path: `${pathname}` });
    }, [pathname]);
};