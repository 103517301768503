import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';

import Layout from './layout/Layout';
import WithoutTopMenuI18nLayout from './layout/WithoutTopMenuI18nLayout';
import WithoutTopMenuLayout from './layout/WithoutTopMenuLayout';


import './resources/styles/YouTubePopUp.css';
import './resources/styles/default.css';
// import './resources/styles/style.css';
// import './resources/styles/style.dev.css';
import './resources/styles/common.css';
import './resources/styles/header.css';
import './resources/styles/footer.css';
import './resources/styles/content.css';

import Jobs from './pages/Careers/Jobs';
import Life from './pages/Careers/Life';
import Contact from './pages/Contact';
import ContentMarketing from './pages/ContentMarketing';
import Home from './pages/Home';
import Localization from './pages/Localization';
import PlayerSupport from './pages/PlayerSupport';
import Portfolio from './pages/Portfolio';
import QA from './pages/QA';
import Why2bytes from './pages/Why2btyes';
import ContextProvider from './ContextProvider';
import GameSound from './pages/GameSound';
import CoPublishing from './pages/CoPublishing';

import Privacy from './pages/Privacy';
import Privacy20220111 from './pages/Privacy20220111';

import Notice20220331 from './pages/Notices/Notice20220331';
import Notice20220517 from './pages/Notices/Notice20220517';
import Notice20230220 from './pages/Notices/Notice20230220';
import Notice20230313 from './pages/Notices/Notice20230313';
import Notice20230316 from './pages/Notices/Notice20230316';
import Notice20230720 from './pages/Notices/Notice20230720';
import Notice20230901 from './pages/Notices/Notice20230901';
import Notice20230916 from './pages/Notices/Notice20230916';
import Notice20240110 from './pages/Notices/Notice20240110';
import Notice20240313 from './pages/Notices/Notice20240313';
import Notice20240814 from './pages/Notices/Notice20240814';
import Notice20241017 from './pages/Notices/Notice20241017';
import Notice20241024 from './pages/Notices/Notice20241024';

import NotFound from './pages/NotFound';


import './i18n';

import { useTranslation } from 'react-i18next';
import apiGetNotionCareers from './api/getNotionCareers';
import { useTracking } from "./hooks/UseTracking.js";

// console.log(`%c${process.env.NODE_ENV}`, 'color:red');

export default function App() {
    const { i18n } = useTranslation();
    const pathname = useLocation();
    const navigate = useNavigate();

    const { divisionPathname } = useParams();

    const [notionData, setNotionData] = useState({
        jobs: [],
        divisions: [],
        blogs: [],
        groups: [],
    });




    const languageInt = () => {
        const languageList = [
            { 'lang': 'ko', 'code': ["ko", "ko-kr", "kr"], 'label': '한국어' },
            { 'lang': 'en', 'code': ["en", "en-us", "us"], 'label': 'ENGLISH' },
            { 'lang': 'ja', 'code': ["ja", "ja-jp", "jp"], 'label': '日本語' },
            { 'lang': 'zh-CN', 'code': ["zh", "zh-CN", "cn", "zh-cn"], 'label': '简体中文' },

        ];
        const langPath = pathname.pathname.split("/").length > 1 ? pathname.pathname.split("/")[1] : i18n.language;
        const currentLanguage = languageList.find(l => l.code.includes(langPath.toLowerCase()));

//         if (currentLanguage) {
//             if (currentLanguage.lang === langPath) {
//                 if (i18n.language != currentLanguage.lang) {
//                     window.userSelectedLanguage = currentLanguage.lang;
//                     i18n.changeLanguage(currentLanguage.lang);
//                     if (currentLanguage.lang) {
//                         document.documentElement.setAttribute('lang', currentLanguage.lang);
//                     }
//                 }

//             } else {
//                 const to = location.pathname.replace(langPath, currentLanguage.lang);
//                 navigate(to, { replace: false });
//             }
//         } else {
//             const navigatorCode = navigator.language;
//             const languageCode = languageList.find(l => l.code.includes(navigatorCode.toLowerCase()));
// //            console.log("222", navigatorCode, languageCode);
//             if (languageCode) {
//                 navigate(`/${languageCode.lang}${pathname.pathname}`, { replace: true });
//                 // navigate(`/${languageCode.lang}${pathname.pathname}`);
//             } else {
//                 navigate(`/en${pathname.pathname}`, { replace: true });
//             }
//         }
        if (currentLanguage) {
            if (i18n.language !== currentLanguage.lang) {
                window.userSelectedLanguage = currentLanguage.lang;
                i18n.changeLanguage(currentLanguage.lang);
            }
            document.documentElement.setAttribute('lang', currentLanguage.lang);
        } else {
            const navigatorCode = navigator.language;
            const languageCode = languageList.find(l => l.code.includes(navigatorCode.toLowerCase()));
            const newLang = languageCode ? languageCode.lang : 'en';
            window.userSelectedLanguage = newLang;
            i18n.changeLanguage(newLang);
            document.documentElement.setAttribute('lang', newLang);
            navigate(`/${newLang}${pathname.pathname}`, { replace: true });
        }
    };

    const loadNotionData = async () => {
        // console.log('loadNotionData');
        const jobs = await apiGetNotionCareers.getJobs();
        const divisions = await apiGetNotionCareers.getDivisions();
        const blogs = await apiGetNotionCareers.getBlogs();
        const groups = apiGetNotionCareers.groupDivisions(divisions, jobs);

        // console.log(jobs, divisions, blogs, groups);

        setNotionData({ jobs, divisions, blogs, groups });
    };

    const slashPathname = () => {
        const regExp = /!$/;
        const { pathname: p } = pathname;
        if (regExp.test(p)) {
            console.log('!!!!!!!!!!');
            navigate(p.replace(regExp, ''), { replace: true });
            return false;
        }
        return true;
    };

    useEffect(() => {
        loadNotionData();
    }, []);

    useEffect(() => {
        if (slashPathname()) {
            languageInt();
        }
    }, [pathname]);

    useTracking();


    return (
        <Routes>
            <Route path={`${i18n.language}`}>
                <Route element={<Layout />}>
                    <Route index path={``} element={<Home />} />
                    <Route path={`Why2Bytes`} element={<Why2bytes />} />
                    <Route path={`Services/Localization`} element={<Localization />} />
                    <Route path={`Services/QA`} element={<QA />} />
                    <Route path={`Services/PlayerSupport`} element={<PlayerSupport />} />
                    <Route path={`Services/ContentMarketing`} element={<ContentMarketing />} />
                    <Route path={`Services/GameSound`} element={<GameSound />} />
                    <Route path={`Services/CoPublishing`} element={<CoPublishing />} />
                    <Route path={`Portfolio`} element={<Portfolio />} />
                   <Route path={`OurWorks`} element={<Navigate replace to="/Portfolio" />} />
                    <Route path={`Contact`} element={<Contact />} />

                    <Route path={`Careers`} element={<Navigate replace to="/Careers/Life" />} />
                    <Route path={`Careers/Life`} element={<Life {...{ notionData }} />} />
                    {/* <Route path={`Careers/Jobs`} element={<Jobs {...{ notionData }} />} /> */}
                    <Route path={`Careers/Jobs`} element={<Navigate replace to={`/${i18n.language}/Careers/Jobs/GlobalContent`} />} />
                    <Route path={`Careers/Jobs/:divisionPathname`} element={<Jobs {...{ notionData }} />} />
                    <Route path={`Careers/Jobs/:divisionPathname/:jobNo`} element={<Jobs {...{ notionData }} />} />
                    {/* 
                    <Route path={`Careers`} element={<Careers />}>
                        <Route path={`Life`} element={<Life {...{ notionData }} />} />
                        <Route path={`Jobs`} element={<Jobs {...{ notionData }} />} />
                        <Route path={`Jobs/:divisionPathname`} element={<Jobs {...{ notionData }} />} />
                        <Route path={`Jobs/:divisionPathname/:jobNo`} element={<Jobs {...{ notionData }} />} />
                    </Route>
                    */}
                </Route>
                <Route element={<WithoutTopMenuLayout />}>
                    <Route path={`Privacy`} element={<Privacy />} />
                    <Route path={`Privacy/20220111`} element={<Privacy20220111 />} />
                </Route>
                <Route element={<WithoutTopMenuI18nLayout />}>
                    <Route path={`Notice20220331`} element={<Notice20220331 />} />
                    <Route path={`Notice20220517`} element={<Notice20220517 />} />
                    <Route path={`Notice20230220`} element={<Notice20230220 />} />
                    <Route path={`Notice20230313`} element={<Notice20230313 />} />
                    <Route path={`Notice20230316`} element={<Notice20230316 />} />
                    <Route path={`Notice20230720`} element={<Notice20230720 />} />
                    <Route path={`Notice20230901`} element={<Notice20230901 />} />
                    <Route path={`Notice20230916`} element={<Notice20230916 />} />
                    <Route path={`Notice20240110`} element={<Notice20240110 />} />
                    <Route path={`Notice20240313`} element={<Notice20240313 />} />
                    <Route path={`Notice20240814`} element={<Notice20240814 />} />
                    <Route path={`Notice20241017`} element={<Notice20241017 />} />
                    <Route path={`Notice20241024`} element={<Notice20241024 />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
};
