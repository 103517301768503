/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

function Content({ notionData }) {
    const { i18n } = useTranslation();
    console.log(notionData?.blogs?.sort((a,b)=>a.NO<b.NO?-1:1).map(e=>e.NO+e.제목))

    return notionData?.blogs?.sort((a,b)=>a.NO<b.NO?-1:1)?.map((item, index) => <div key={index}  css={css`
            width: 33%;
            float: left;
            @media (max-width: 992px) {
                width: 100%;
                
        }
    `}>
        <a
            href={item.블로그링크}
            target="_blank"
            className="item_inner"
            rel="noreferrer"
            css={css`
                background: #FFFFFF;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
                text-align: center;
                &:hover {
                    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.1);
                    transform: translateY(-5px);
                    transition: transform 0.2s ease 0s;
                    
                }
                &:hover .title  {
                    color: #743FA3;
                    
                }
               
            `}
        >

            <span className='thumb' style={{margin: 0}}>
                <img src={item.이미지링크} alt="" />
            </span>
            <div className="info" 
                css={css`
                padding: 2rem 2rem 3rem;
                min-height: 160px;
                @media (max-width: 1320px) {
                    min-height: 170px;
                }
                @media (max-width: 992px) {
                    
                    min-height: auto;
                }
            `}>
                <div className='title' css={css`
                    font-weight: 700;
                    font-size: 1.8rem;
                    line-height: 3rem;
                    margin-bottom: 1rem;
                `}>{item.제목}</div>
                <div className="desc" css={css`
                    font-weight: 400;
                    font-size: 1.8rem;
                    line-height: 3rem;
                `}>{item.설명}</div>
            </div>
        </a></div>);
}

export default function Blog({ notionData }) {
    
    return <section className="careers section_blog" style={{ display: notionData?.blogs?.length != 3 ? 'none' : '' }}>
        <div className="inner" >
            <div className="h_title_box">
                <h3 className="h_title">
                    투바이트 분위기를 미리 느껴보세요
                </h3>
            </div>

            <div  css={css`
            display: flex;
            flex-direction: row;
            gap: 5.7rem;
            @media (max-width: 1320px) {
                gap: 2.3rem;
            }
            @media (max-width: 992px) {
                width: 100%;
                gap: 2.4rem;
                flex-direction: column;
            }
        `}>
                <Content {...{ notionData }} />
            </div>
        </div>
    </section>;
};


