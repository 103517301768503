export default async function uploadViaPresignedPost(file, fileName) {
    // Get presigned POST URL and form fields
    let encodedFilename = encodeURI(fileName);

    let response = await fetch(
        `https://mz8tek24g2.execute-api.ap-northeast-2.amazonaws.com/production/s3-upload?fileName=${encodedFilename}`
    ); // s3에게 signed-post 절차 받기
    let json = await response.json(); // response를 역직렬화

    // Build a form for the request body
    let form = new FormData();
    Object.keys(json.data.fields).forEach((key) => form.append(key, json.data.fields[key])); // form data 구축
    form.append('file', file); // form 마지막에 file 붙이기

    // Send the POST request
    response = await fetch(json.data.url, { method: 'POST', body: form }); // 성공적으로 업로드 시 response header Location에 해당 객체 주소
    if (!response.ok) return 'Failed to upload via presigned POST';

    // Done!
    // return `File uploaded via presigned POST with key: ${json.id}`
    return `${json.data.url}/${json.id}`;
}
