import React, { useEffect } from 'react';

function Notice20220331() {

    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);

    return (
        <div id="container" className="cont_nt">
            <section className="section">
                <div className="inner">
                    <div className="title-box">
                        <h2>
                            제2기 정기주주총회 소집 공고
                        </h2>
                        <p>주주님의 건승과 댁내의 평안을 기원합니다.<br />당사는 정관 제19조에 근거하여 제2기 정기주주총회를 아래와 같이 소집 공고 하오니 참석하여주시기바랍니다.<br /><br /></p>
                    </div>

                    <div className="dl_box">
                        <dl>
                            <dt>
                                1. 일 시 : 2022년 3월 31일(목) 오전 11시


                            </dt>

                        </dl>
                        <dl>
                            <dt>
                                2. 장 소 : 경기도 안양시 동안구 부림로 170번길 38, 본사 6층 회의실

                            </dt>

                        </dl>
                        <dl>
                            <dt>
                                3. 회의목적사항

                            </dt>
                            <dd>
                                <dl>
                                    <dt>
                                        1) 보고사항
                                    </dt>
                                    <dd >
                                        : 영업보고
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        2) 부의안건
                                    </dt>
                                    <dd >
                                        제1호 의안 : 제2기(2021.01.01~2021.12.31) 재무제표 승인의 건 <br />
                                        제2호 의안 : 이사 보수한도 승인의 건
                                    </dd>
                                </dl>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                4. 경영참고사항 비치

                            </dt>
                            <dd >
                                상법 제 542조의 4에 의거 경영참고사항을 당사에 비치하오니 참고하시기 바랍니다.

                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                5. 의결권 대리행사 관련 사항
                            </dt>
                            <dd style={{ marginLeft: "30px" }}>
                                <span className='not-indent'>1)</span>
                                금번 당사의 주주총회는 주주님께서 참석하시어 의결권을 직접 행사하시거나 또는 위임장에 의거 의결권을 간접 행사하실 수 있습니다. 위임장을 작성하신 후 회사로 우편 송부하여 행사하실 수 있습니다.
                                <span> 위임장 보내실 곳 :  경기도 안양시 동안구 부림로 170번길 38, 6층 투바이트㈜ 재무팀 주주총회 담당자 앞 (우편번호 14055)</span>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                6.주주총회 참석시 준비물

                            </dt>
                            <dd style={{ marginLeft: "30px" }}>
                                <span className='not-indent'>1)</span> 직접행사 :  신분증 <br />
                                <span className='not-indent'>2)</span> 대리행사 :  위임장(주주와 대리인의 인적사항 기재, 인감 날인), 주주의 인감증명서 1부, 대리인의 신분증
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                7. 코로나19 관련 안내 사항

                            </dt>
                            <dd>
                                주주총회 당일 발열, 기침증세로 의심되는 경우 또는 마스크를 착용하지 않은 경우 주주의 출입이  제한될 수 있음을 알려 드립니다. 코로나바이러스 감염증-19(COVID-19) 확산에 따른 불가피한 장소 변경이 있는 경우, 지체없이 재공고 할 예정입니다.
                            </dd>
                        </dl>
                        <div >
                            <p className="footer-text">
                                2022년 03월 22일<br /><br />

                                투바이트 주식회사<br />
                                경기도 안양시 동안구 부림로 170번길 38, 6층<br />
                                대표이사 함영철<br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Notice20220331;
