/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ill_cm from '../resources/images/ill_cm.png?url';
import ll_services_on from '../resources/images/ill_ll_services_on.png';
import ps_services_on from '../resources/images/ill_ps_services_on.png';
import cm_services_on from '../resources/images/ill_cm_services_on.png';
import qa_services_on from '../resources/images/ill_qa_services_on.png';
import gs_services_on from '../resources/images/ill_gs_services_on.png';
import cp_services_on from '../resources/images/ill_cp_services_on.png';
import ll_services_off from '../resources/images/ill_ll_services_off.png';
import ps_services_off from '../resources/images/ill_ps_services_off.png';
import cm_services_off from '../resources/images/ill_cm_services_off.png';
import qa_services_off from '../resources/images/ill_qa_services_off.png';
import gs_services_off from '../resources/images/ill_gs_services_off.png';
import cp_services_off from '../resources/images/ill_cp_services_off.png';

export default function Services() {

    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    const { pathname } = useLocation();
    const currentRoute = pathname.trim()
    const navigate = useNavigate();
    useEffect(() => {
        $('.open_modal').YouTubePopUp();
    }, []);

    const pageLink = (page) => {
        const currentPath = pathname.trim().split('/')[3];
        if (page === currentPath) {
            $(window).scrollTop(0);
        } else if (page === "Localization") {
            navigate(`/${i18n.language}/Services/Localization`);
        }
        else if (page === "QA") {
            navigate(`/${i18n.language}/Services/QA`);
        }
        else if (page === "PlayerSupport") {
            navigate(`/${i18n.language}/Services/PlayerSupport`);
        }
        else if (page === "ContentMarketing") {
            navigate(`/${i18n.language}/Services/ContentMarketing`);
        }
        else if (page === "GameSound") {
            navigate(`/${i18n.language}/Services/GameSound`);
        }
        else if (page === "CoPublishing") {
            navigate(`/${i18n.language}/Services/CoPublishing`);
        }
    };
    
    const handleMouseEnter = (event) => {
        const box = event.target.closest('.box');
        const titleElement = box.querySelector('.title');
        if (titleElement && titleElement.classList.contains('off')) {
            box.classList.add('active');
        }
    };

    const handleMouseLeave = (event) => {
        const box = event.target.closest('.box');
        const titleElement = box.querySelector('.title');
        if (titleElement && titleElement.classList.contains('off')) {
            box.classList.remove('active');
        }
    };

    useEffect(() => {
        const buttons = document.querySelectorAll('.btn_basic');
        buttons.forEach(button => {
            button.addEventListener('mouseenter', handleMouseEnter);
            button.addEventListener('mouseleave', handleMouseLeave);
        });

        return () => {
            buttons.forEach(button => {
                button.removeEventListener('mouseenter', handleMouseEnter);
                button.removeEventListener('mouseleave', handleMouseLeave);
            });
        };
    }, []);


    return (
        <section className="section_why">
            <div className="inner">
                <div className="h_title_box">
                    <h3 className="h_title">
                        {pt('Why2Bytes_1')}
                    </h3>
                </div>

                <div className="services_box">
                    <div className={`box col1_sm ll ${currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('Localization') ? 'active' : ''}`}>
                        <p className="team_name off">
                            {pt('Footer_5')}
                        </p>
                        <strong className={
                            currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('Localization')
                            ? 'title'
                            : 'title off'
                        }>
                            {pt('Why2Bytes_3')}
                        </strong>
                        <span className="img_box"></span>
                        {/* <img src={
                           currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('Localization')
                           ? ll_services_on
                           : ll_services_off
                        } alt="Localization/LQA image" /> */}
                        <button onClick={() => pageLink("Localization")} className={
                           currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('Localization')
                           ? 'btn_basic'
                           : 'btn_basic off'
                        }>
                            {pt('Why2Bytes_5')}
                        </button>
                    </div>

                    <div className={`box col1_sm ps ${currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('PlayerSupport') ? 'active' : ''}`}>
                        <p className="team_name off">
                            {pt('Footer_7')}
                        </p>
                        <strong className={
                            currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('PlayerSupport')
                            ? 'title'
                            : 'title off'
                        }>
                            {pt('Why2Bytes_15')}
                        </strong>
                        <span className="img_box"></span>
                        {/* <img src={
                           currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('PlayerSupport')
                           ? ps_services_on
                           : ps_services_off
                        } alt="Game Operation image" /> */}
                        <button onClick={() => pageLink("PlayerSupport")} className={
                          currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('PlayerSupport')
                          ? 'btn_basic'
                          : 'btn_basic off'
                        }>
                            {pt('Why2Bytes_5')}
                        </button>
                    </div>

                    <div className={`box col1_sm cm ${currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('ContentMarketing') ? 'active' : ''}`}>
                        <p className="team_name off">
                            {pt('Footer_15')}
                        </p>
                        <strong className={
                            currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('ContentMarketing')
                            ? 'title'
                            : 'title off'
                        }>
                            {pt('Why2Bytes_18')}
                        </strong>
                        <span className="img_box"></span>
                        {/* <img src={
                           currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('ContentMarketing')
                           ? cm_services_on
                           : cm_services_off
                        } alt="Content Marketing image" /> */}
                        <button onClick={() => pageLink("ContentMarketing")} className={
                           currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('ContentMarketing')
                           ? 'btn_basic'
                           : 'btn_basic off'
                        }>
                            {pt('Why2Bytes_5')}
                        </button>
                    </div>

                    <div className={`box col1_sm qa ${currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('QA') ? 'active' : ''}`}>
                        <p className="team_name off">
                            {pt('Footer_6')}
                        </p>
                        <strong className={
                            currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('QA')
                            ? 'title'
                            : 'title off'
                        }>
                            {pt('Why2Bytes_10')}
                        </strong>
                        <span className="img_box"></span>
                        {/* <img src={
                           currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('QA')
                           ? qa_services_on
                           : qa_services_off
                        } alt="Quality Assurance image" /> */}
                        <button onClick={() => pageLink("QA")} className={
                           currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('QA')
                           ? 'btn_basic'
                           : 'btn_basic off'
                        }>
                            {pt('Why2Bytes_5')}
                        </button>
                    </div>

                     <div className={`box col1_sm gs ${currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('GameSound') ? 'active' : ''}`}>
                        <p className="team_name off">
                            {pt('Footer_16')}
                        </p>
                        <strong className={
                            currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('GameSound')
                            ? 'title'
                            : 'title off'
                        }>
                            {pt('Why2Bytes_21')}
                        </strong>
                        <span className="img_box"></span>
                        {/* <img src={
                           currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('GameSound')
                           ? gs_services_on
                           : gs_services_off
                        } alt="Game Sound/Voice-Over image" /> */}
                        <button onClick={() => pageLink("GameSound")} className={
                           currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('GameSound')
                           ? 'btn_basic'
                           : 'btn_basic off'
                        }>
                            {pt('Why2Bytes_5')}
                        </button>
                    </div>

                    <div className={`box col1_sm cp ${currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('CoPublishing') ? 'active' : ''}`}>
                        <p className="team_name off">
                            {pt('Footer_17')}
                        </p>
                         <strong className={
                            currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('CoPublishing')
                            ? 'title'
                            : 'title off'
                         }>
                            {pt('Why2Bytes_23')}
                        </strong>
                        <span className="img_box"></span>
                        {/* <img src={
                           currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('CoPublishing')
                           ? cp_services_on
                           : cp_services_off
                        } alt="Co-Publishing image" /> */}
                        <button onClick={() => pageLink("CoPublishing")} className={
                           currentRoute.endsWith('Why2Bytes') || currentRoute.endsWith('CoPublishing')
                           ? 'btn_basic'
                           : 'btn_basic off'
                        }>
                            {pt('Why2Bytes_5')}
                        </button>
                    </div>

                </div>
            </div>
        </section>
    );
}
