/** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Box } from '@mui/material';
import { css } from '@mui/material';
import img_why2bytes from '../resources/images/img_why2bytes.png';


export default function SectionTop(props) {
    const { title } = props;

    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    return (
        <Box component="section" className="section_top section_top_why2bytes" sx={{
            pointerEvents: 'none',
            '@media screen and (max-width: 992px)': {
                paddingTop: '0px !important',
            }
        }}>
            <Box className="page_cover" sx={{
                '@media (max-width=992px':{
                    backgroundImage: `url(${img_why2bytes})`,
                    backgroundPosition: '-67rem top',
                }

            }}>
                <div className="inner">
                    <h2 className="cont_title">
                        {pt('SectionTop_2')}
                    </h2>
                    <p className="desc">
                        <span className='hidden_sm'>
                            {pt('SectionTop_3')}
                        </span>
                        <span className='visible_sm'>
                            <strong>
                                {pt('SectionTop_7')}
                                {/* 성공하는 글로벌 게임, 그 과정을 함께하는 파트너사로 기억되고 싶습니다. */}
                            </strong>
                        </span>
                    </p>
                </div>
            </Box>

            <Box className="tab_wrap fixed_top" sx={{
                '&::after': {
                    backgroundColor: '#4b505e !important',
                },
                '@media screen and (max-width: 992px)': {
                    display: 'none !important',
                }
            }}>
                <div className="tab_box">
                    <ul className="tab_list">
                        <Box component="li">
                            <Link to={`/${i18n.language}/Why2Bytes`}>
                                <span css={css`
                                    display: block;
                                    width: 200%;
                                    height: 25.6rem;
                                    background-color: #4b505e!important;
                                    background-image: url(${img_why2bytes})!important;
                                    background-position: left center;
                                    background-size: cover;
                                    background-repeat: no-repeat!important;
                                    transition: background .4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
                                    @media (max-width:992px) {
                                        display: none!important;
                                    }
                                `} />
                                <strong className="text hidden_sm" style={{ color: '#1DD1D3', width:'200%' }}>
                                    Why 2bytes
                                </strong>
                                <strong className="text visible_sm" style={{ color: '#1DD1D3', width:'200%' }}>
                                    Why 2bytes
                                </strong>
                            </Link>
                        </Box>
                    </ul>
                </div>
            </Box>
        </Box>
    );
}
