/** @jsxImportSource @emotion/react */
import React, { useEffect} from 'react';
import SectionTop from '../layout/SectionTop';
import SectionBottom from '../layout/SectionBottom';
import Services from '../components/Services'
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const GameSound = (props) => {
  const { t, i18n } = useTranslation();
  const pt = (v) => parse(i18n.t(v));

  useEffect(() => {
    $(window).trigger('scroll');
    $(window).scrollTop(0);
  }, []);
  return (
    <div id="container" className="cont_gv">
        <SectionTop title="Game Sound/Voice-Over" mtitle="GS" />
        <section className="section_discript">
            <div className="inner">
                <strong className="title">
                    {pt('GS_step')}
                </strong>

                <div className="step_box">
                    <dl>
                        <dt>
                            {pt('GS_step_1_title')}
                        </dt>
                        <dd>
                            {pt('GS_step_1_content')}
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            {pt('GS_step_2_title')}
                        </dt>
                        <dd>
                            {pt('GS_step_2_content')}
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            {pt('GS_step_3_title')}
                        </dt>
                        <dd>
                            {pt('GS_step_3_content')}
                        </dd>
                    </dl>
                </div>

                <div className='bg_img_content'>
                    <strong className='title'>{pt('GS_sound')}</strong>
                    <div className='left_content type1'>
                        <strong className='title'>{pt('GS_sound_1_title')}</strong>
                        <p>{pt('GS_sound_1_content')}</p>
                    </div>
                    <div className='right_content type2'>
                        <strong className='title'>{pt('GS_sound_2_title')}</strong>
                        <p>{pt('GS_sound_2_content')}</p>
                    </div>
                    <div className='left_content type3'>
                        <strong className='title'>{pt('GS_sound_3_title')}</strong>
                        <p>{pt('GS_sound_3_content')}</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="section_player">
            <div className="text_box">
                <div className="inner d_table">
                    <div className="d_cell text_primary">
                        {pt('GS_contect')}
                    </div>
                    <div className="d_cell btn_area">
                        <a
                            href={`/${i18n.language}/Contact`}
                            className="btn_basic btn_primary btn_xl btn_pop_open"
                        >
                            {pt('QA_11')}
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section className="section_contents">
            <div className="inner">
                <div className='process_wrap'>
                    <strong className='title'>{pt('GS_sound_process')}</strong>
                    <div className='process_box'>
                        <div className='title_box'>
                            <strong>{pt('GameSound')}</strong>
                            <strong>{pt('VoiceOver')}</strong>
                        </div>
                        <div className='content_box'>
                            <strong className='col_title'>{pt('PreProduction')}</strong>
                            <div className='item_box'>
                                <div className='item'>
                                    <strong className='list_title'>{pt('GS_pre_production1_title')}</strong>
                                    <ul>
                                        <li>{pt('GS_pre_production1_list1')}</li>
                                        <li>{pt('GS_pre_production1_list2')}</li>
                                        <li>{pt('GS_pre_production1_list3')}</li>
                                    </ul>
                                </div>
                                <div className='item'>
                                    <strong className='list_title'>{pt('GS_pre_production2_title')}</strong>
                                    <ul>
                                        <li>{pt('GS_pre_production2_list1')}</li>
                                        <li>{pt('GS_pre_production2_list2')}</li>
                                    </ul>
                                </div>
                                <div className='item'>
                                    <strong className='list_title'>{pt('GS_pre_production3_title')}</strong>
                                    <ul>
                                        <li>{pt('GS_pre_production3_list1')}</li>
                                        <li>{pt('GS_pre_production3_list2')}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='item_box'>
                                <div className='item'>
                                    <strong className='list_title'>{pt('VO_pre_production1_title')}</strong>
                                    <ul>
                                        <li>{pt('VO_pre_production1_list1')}</li>
                                        <li>{pt('VO_pre_production1_list2')}</li>
                                    </ul>
                                </div>
                                <div className='item'>
                                    <strong className='list_title'>{pt('VO_pre_production2_title')}</strong>
                                    <ul>
                                        <li>{pt('VO_pre_production2_list1')}</li>
                                        <li>{pt('VO_pre_production2_list2')}</li>
                                    </ul>
                                </div>
                                <div className='item'>
                                    <strong className='list_title'>{pt('VO_pre_production3_title')}</strong>
                                    <ul>
                                        <li>{pt('VO_pre_production3_list1')}</li>
                                        <li>{pt('VO_pre_production3_list2')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='content_box'>
                            <strong className='col_title'>{pt('Production')}</strong>
                            <div className='item_box'>
                                <div className='item'>
                                    <strong className='list_title'>{pt('GS_production1_title')}</strong>
                                    <ul>
                                        <li>{pt('GS_production1_list1')}</li>
                                        <li>{pt('GS_production1_list2')}</li>
                                    </ul>
                                </div>
                                <div className='item'>
                                    <strong className='list_title'>{pt('GS_production2_title')}</strong>
                                    <ul>
                                        <li>{pt('GS_production2_list1')}</li>
                                        <li>{pt('GS_production2_list2')}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='item_box'>
                                <div className='item'>
                                    <strong className='list_title'>{pt('VO_production1_title')}</strong>
                                    <ul>
                                        <li>{pt('VO_production1_list1')}</li>
                                    </ul>
                                </div>
                                <div className='item'>
                                    <strong className='list_title'>{pt('VO_production2_title')}</strong>
                                    <ul>
                                        <li>{pt('VO_production2_list1')}</li>
                                        <li>{pt('VO_production2_list2')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='content_box'>
                            <strong className='col_title'>{pt('PostProduction')}</strong>
                            <div className='item_box'>
                                <div className='item'>
                                    <strong className='list_title'>{pt('GS_post_production1_title')}</strong>
                                    <ul>
                                        <li>{pt('GS_post_production1_list1')}</li>
                                        <li>{pt('GS_post_production1_list2')}</li>
                                    </ul>
                                </div>
                                <div className='item'>
                                    <strong className='list_title'>{pt('GS_post_production2_title')}</strong>
                                    <ul>
                                        <li>{pt('GS_post_production2_list1')}</li>
                                        <li>{pt('GS_post_production2_list2')}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='item_box'>
                                <div className='item'>
                                    <strong className='list_title'>{pt('VO_post_production1_title')}</strong>
                                    <ul>
                                        <li>{pt('VO_post_production1_list1')}</li>
                                        <li>{pt('VO_post_production1_list2')}</li>
                                    </ul>
                                </div>
                                <div className='item'>
                                    <strong className='list_title'>{pt('VO_post_production2_title')}</strong>
                                    <ul>
                                        <li>{pt('VO_post_production2_list1')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Services />
      <SectionBottom />
    </div>
  );
}

export default GameSound;
