import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

function SectionBottom() {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    return (
        <section className="section_bottom">
            <div className="inner d_table">
                <div className="d_cell text_white">
                    {pt('SectionBottom_1')}
                    {/* 작은 차이로 달라지는 게임 서비스 품질.
                        <br />
                        피땀 흘려 개발한 게임이 빛을 볼 수 있도록 한 팀처럼 일합니다. */}
                </div>
                <div className="d_cell btn_area">
                    <Link to={`/${i18n.language}/Contact`} className="btn_basic btn_xl btn_pop_open">
                        {pt('SectionBottom_2')}
                        {/* 회사소개서 다운로드 */}
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default SectionBottom;
