import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

function NotFound() {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));
    const { pathname } = useLocation();

    const hidden = useMemo(() => pathname.indexOf(`/${i18n.language}/`) === -1, [pathname]);

    return (
        <div hidden={hidden} className="error_page">
            <h1 className="logo">
                {pt('NotFound_1')}
                {/* 2bytes Byte the Word */}
            </h1>

            <strong className="text_primary">
                {pt('NotFound_2')}
                {/* 앗! 페이지가 없습니다. */}
            </strong>

            <div className="cont">
                {pt('NotFound_3')}
                {/* 작은 차이로 달라지는 게임 서비스 품질.<br />
                피땀 흘려 개발한 게임이 빛을 볼 수 있도록 한 팀처럼 일합니다.<br />
                2bytes 는 게임 글로벌 런칭을 위한 최적의 파트너입니다. */}
            </div>

            <div className="btn_area">
                <a href="/" className="btn_primary btn_basic btn_lg">
                    {pt('NotFound_4')}
                    {/* 투바이트 홈 바로가기 */}
                </a>
            </div>

        </div>

    );
}

export default NotFound;
