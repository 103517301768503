import React, { useEffect } from 'react';

function Notice() {

    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);

    // 노출 기간 :  2023년 3월 16일(목) 오전 중 ~ 2023년 3월 31일(금) 오전 중

    return (
        <div id="container" className="cont_nt">
            <section className="section">
                <div className="inner">
                    <div className="title-box">
                        <h2>
                        제3자배정 유상증자 신주발행 공고
                        </h2>
                        <p>
                        상법 제416조 및 정관 제9조 2항 2호 규정에 의거하여 2023년 7월 20일 이사회에서 아래와 같이 신주발행을 결의하였으므로 상법 제418조에 따라 이를 공고합니다.<br /><br />
                        </p>
                    </div>

                    <div className="dl_box">

                        <dl>
                            <dt>
                                1. 신주식의 종류와 수 : 전환상환우선주식 28,445주
                            </dt>
                        </dl>

                        <dl>
                            <dt>
                                2. 1주의 발행가액 : 금35,156원(액면가 500원)
                            </dt>

                        </dl>
                        <dl>
                            <dt>
                                3. 납입기일 : 2023년 8월 4일
                            </dt>
                        </dl>
                        <dl>
                            <dt>
                                4. 신주식의 인수방법 : 정관 제9조 2항 2호에 의하여 제3자에게 배정 함
                            </dt>
                        </dl>
                        <dl>
                            <dt>
                                5. 제3자배정 유상증자와 관련하여 납입기일은 변동 가능하며, 대표이사에게 위임 함
                            </dt>
                        </dl>
                        <div >
                            <p className="footer-text">
                                2023년 07월 20일
                                <br />
                                <br />
                                투바이트 주식회사<br />
                                경기도 안양시 동안구 시민대로 327번길 24, 6층, 7층, 8층(관양동)<br />
                                대표이사 함영철<br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Notice;
