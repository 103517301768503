import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function ActiveLink({ children, activeClassName, ...props }) {
    const { pathname } = useLocation();
    const originClassName = props.className || '';

    const currentPath = pathname.trim().split('/')[2];
    const asPath = props.as || null;

    const className =
        pathname.indexOf(props.to) > 0 || currentPath === asPath
            ? `${originClassName} ${activeClassName}`.trim()
            : originClassName;

    return (
        <Link {...props} className={className || null}>
            {children}
        </Link>
    );
}
