import React from 'react';
import { css } from '@mui/material';

function PortfolioCard(props) {

    const { item } = props;

    let supports = item.supports.split('\n');
    let languages = item.languages.split(/\s*\n\s*/g).filter(e => e.trim()).map((e) => ' ' + e);

    return (
        <li className="item">
            <div className={`card `}>
                <div className="thumb">
                    <img src={item.thumbImg} style={{ width: '100%' }} alt="" />
                </div>
                <div className={'info'} >
                    <div className="box">
                        <div className="top">
                            <p className="corp">{item.corp}</p>
                            <p className="title">{item.title}</p>
                        </div>
                        <div className="mid">
                            <div className="flx_1">
                                <span className="type">{item.type}</span>
                                <div className="supports">
                                    {supports.map((item, index) => {
                                        return (
                                            <i className={`icon_${String(item).toLowerCase()}`} key={item + index}>
                                                {item}
                                            </i>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="bottom">
                            <p className="part">{item.part}</p>
                            <ul>
                                {languages.map((item, index) => {
                                    return <li key={item + index} style={
                                        document.getRootNode()?.children?.item?.(0)?.lang === 'zh-CN' ? {
                                            wordBreak: 'break-all',
                                        } : {}
                                    }>{item}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <span className="company">
                <img src={item.companyImg} alt="" />
            </span>
        </li>
    );
}

export default PortfolioCard;
