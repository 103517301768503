/** @jsxImportSource @emotion/react */
import {
    useState,
    useMemo,
    useCallback,
    useRef,
    forwardRef,
    useImperativeHandle
} from 'react';
import StepCss from './StepCss';

const StepFinished = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        isFinished: true
    }));

    return (
        <div css={StepCss.root}>
            <div css={StepCss.titleRoot}>
                <div css={StepCss.finshedTitle}>
                    {props.title ?? '지원해주셔서 감사합니다.'}
                </div>
                <div css={StepCss.finshedTitle2}>
                    {props.desc ?? '서류 합격자에 한해 개별적으로 연락을 드리겠습니다.'}
                </div>
            </div>
        </div>
    );
});

export default StepFinished;
