/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Box from '@mui/material/Box';
import SectionTop from '../SectionTop';
import StepForm from './StepForm';
import { css } from '@emotion/react';
import PreLoader from '../../../layout/PreLoader';

const Jobs = ({ notionData }) => {
    // if (notionData.jobs.length) console.log('Notion data is ready.');
    const { t, i18n } = useTranslation();
    const { divisionPathname, jobNo } = useParams();

    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, [divisionPathname, jobNo]);
    return <div id='container'>
        <PreLoader {...{ notionData }} ></PreLoader>
        <SectionTop />
        <Content {...{ notionData, divisionPathname, jobNo }} />
    </div>;
};

const Content = (props) => {
    const { notionData, divisionPathname, jobNo } = props;
    const mobileResolution = 992;
    const [mobileOpenMenu, setMobileOpenMenu] = useState('');


    /*
        설명: 페이지를 아래로 스크롤 했다 다시 올라가면 menu가 보이지 않는 이슈를 해결한다.
        이는 Life at 2bytes | Jobs at 2bytes 메뉴의 position이 바뀌어서 일어난 이슈이다.
        해결 방법은 스크롤을 내렸을 때 menu에게 margin-top을 정해주는 방식이다.
     */
    const careersMenuRef = useRef();
    useEffect(() => {
        const onScroll = (e) => {
            if (window.scrollY > 0) {
                window.removeEventListener('scroll', onScroll);
                careersMenuRef.current.style.marginTop = '5.2rem';
            }
        };
        window.addEventListener('scroll', onScroll);
    }, []);

    const resizeWindow = () => {
        if (window.innerWidth > mobileResolution) {
            setMobileOpenMenu(() => '');
        }
    };

    const onMobileOpenMenu = () => {
        if (mobileOpenMenu === 'open') {
            setMobileOpenMenu(() => '');
        } else if (mobileOpenMenu === '') {
            setMobileOpenMenu(() => 'open');
        }
    };
    useEffect(() => {
        window.addEventListener('resize', resizeWindow);
        window.dispatchEvent(new Event('resize'));
        return () => {
            window.removeEventListener('resize', resizeWindow);
        };
    });

    useEffect(() => {
        setMobileOpenMenu(() => '');
    }, [divisionPathname, jobNo]);




    return <section className="careers section_discript" style={{ marginTop: 0 }}>
        <div className="inner" css={css`
            position: relative;
            width: 127.8rem;
            margin: 0 auto;
            padding: 8rem 0 11.7rem;
            @media screen and (max-width: 1320px)
            {
                width: 100%;
                padding: 10.2rem 2.4rem 7.8rem;
            }
            @media screen and (max-width: 992px)
            {
                padding: 0;
            }
        `}>
            <Box component="div"
                ref={careersMenuRef}
                sx={css`
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
                @media (max-width: 992px) {
                    padding: 0 2.4rem;
                }
            `}>
                <div css={css`
                    font-weight: 700;
                    line-height: 3.6rem;
                    color: #743FA3;
                    font-size: 2.2rem;
                    @media (max-width: 1320px) {
                        font-size: 2.2rem;
                    }
                    @media (max-width: 992px) {
                        font-size: 2rem;
                        padding: 2rem 0;
                    }
                `}>
                    직군별 채용 공고
                </div>

                <div className='boxIcon' onClick={onMobileOpenMenu}>
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                </div>
            </Box>
            <Box sx={css`
                display: flex;
                gap: 2rem;
                padding-top: 1rem;
                @media (max-width: 1320px) {
                    flex-direction: row;
                }
                @media (max-width: 992px) {
                    flex-direction: column;
                    gap: 0;
                    padding-top: 0;
                    
                }
            `}>

                {/* Left: Side bar */}
                <Box className={`${mobileOpenMenu} `} sx={css`
                    width: 30rem;
                    min-width: 30rem;
                    @media (max-width: 1320px) {
                        width: 30rem;
                        min-width: 30rem;
                    }
                    @media (max-width: 992px) {
                        width: 100%;
                        padding: 0 2.4rem;
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height 0.4s ease-in-out;
                    }
                    
                `}>

                    {
                        notionData?.groups?.map((data, key) => <SidebarItemBox
                            key={key}
                            notionData={notionData}
                            {...data} />)
                    }
                    <Box sx={css`
                           margin-top: 8rem;
                            
                            @media (max-width: 992px) {
                                margin-top: 4rem;
                                margin-bottom: 8rem;
                            }
                        `}>
                        <Box sx={css`
                            font-size: 2.0rem;
                            font-weight: 700;
                            @media (max-width: 1320px) {
                                font-weight: 700;
                            }
                            @media (max-width: 992px) {
                                font-weight: 500;
                            }
                        `}>
                            게임 개발에 참여하고 싶으신가요?
                        </Box>
                        <Box className='pentapeakstudios_logo' sx={css`
                            background-color: #FFF;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                            cursor: pointer;
                            margin-top: 1.8rem;
                            
                        `} onClick={() => {
                                open('https://pentapeakstudios.com/', '_blank');
                            }} />
                    </Box>
                </Box>

                {/* Right: Content */}
                <Box sx={css`
                   width: 100%;
                `}>
                    <JobPosting {...props} />
                </Box>
            </Box>
        </div>
    </section >;
};

const SidebarItemBox = (props) => {
    const _params = useParams();// { divisionPathname:'GlobalContent', jobNo:'1 }
    const { 부서, 공고, pathname, notionData } = props;
    const isSamePathname = _params.divisionPathname == pathname;
    const isSameJobNo = 공고?.some(e => e.props.NO == _params.jobNo);
    const _transaction = useTranslation();
    const to = useNavigate();
    const { language } = _transaction.i18n;
    // console.log(language);

    const toHandle = (e) => {
        const item = notionData.divisions.find(item => item.부서 === 부서);
        if (item?.pathname) {
            to(`/${language}/Careers/Jobs/${item.pathname}`);
        }
    };

    return <Box className='SidebarItemBox'
        sx={css`
                background-color: #FFF;
                ${isSamePathname ? 'border: 1px solid #743FA3' : ''};
                margin-bottom: 1rem;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                cursor: pointer;
        `}>
        {/* 부서 공고수 */}
        <Box sx={css`
            color: #000;
            font-size: 1.8rem;
            padding: 2.3rem 2.5rem;
            ${공고?.length > 0 ? 'border-bottom: 2px solid rgba(225, 225, 225, 0.4)' : ''};
            display: flex;
            justify-content: space-between;
            align-items: center;
        `} onClick={toHandle}>
            <Box sx={css`
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-weight: 500;
            `}>
                <Box sx={css`
                    font-weight: 500;
                    color: ${isSamePathname ? '#743FA3' : '#000'};
                `}>{부서}</Box>

                <Box sx={{ px: 1, color: 공고?.length ? '#743FA3' : '#828282' }}>
                    {공고?.length}
                </Box>
            </Box>

            <Box className='careers_right_arrow' sx={{ mr: 0 }} />
        </Box>


        {공고?.length > 0 && (<Box sx={css`
            padding: 0.7rem 0;
            
            @media (max-width: 992px) {
                padding: 0.5rem 0;
            }
            & > *:not(:first-of-type) {
                border-top: 1px solid rgba(225, 225, 225, 0.3);
            }
            `}>{
                공고?.map((job) => <Box sx={css`
                    
                    font-size: 1.6rem;
                    color: ${job.props.NO == _params.jobNo ? '#743FA3' : '#444'};
                    line-height: 2.6rem;
                    padding: 1.2rem 2.5rem;
                    @media (max-width: 992px) {
                        padding: 1.2rem 2rem;
                    }
                    `}
                    onClick={() => {
                        let url = `/${language}/Careers/Jobs/${pathname}/${job.props.NO}`;
                        // console.log(url);
                        to(url);
                    }}
                    key={job.props.NO}>
                    {job.props.공고명}
                </Box>)
            }</Box>
        )}
    </Box>;
};




function JobPosting({ notionData, divisionPathname }) {
    const { i18n } = useTranslation();
    const to = useNavigate();
    const { jobNo } = useParams();
    const division = notionData.groups.find(e => e.pathname === divisionPathname);//{ 부서, 공고, dbId, pathname, }
    const job = division?.공고.find(e => e.props.NO === jobNo);
    const storeRef = useRef({});


    return <>
        {job ? (<Box className='JobPosting' sx={css`
            background-color: #FFF;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        `}>
            <Box component="section" sx={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
                
                border-bottom: 2px solid #EBEBEB;
                padding: 5rem 3rem 3rem;
                @media (max-width: 992px) {
                    align-items: flex-start;
                    padding: 4rem 2rem;
                }
            `}>
                <Box sx={css`
                font-weight: 400;
                font-size: 2.2rem;
                line-height: 3.6rem;
                letter-spacing: -1px;
                color: #111111;
                
                @media (max-width: 992px) {
                    font-size: 2.4rem;
                    line-height: 3.3rem;
                    letter-spacing: -0.5px;
                }
            `}>{job ? division?.부서 : ''}</Box>
                <Box sx={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                @media (max-width: 992px) {
                    flex-direction: column;
                    align-items: flex-start
                }
            `}>

                    <Box sx={css`
                    font-weight: 700;
                    font-size: 3rem;
                    line-height: 4.2rem;
                    letter-spacing: -1px;
                    color: #743FA3;
                    @media (max-width: 1320px) {
                        font-size: 2.8rem;
                        line-height: 3.6rem;
                    }
                    @media (max-width: 992px) {
                        font-size: 3rem;
                        line-height: 4.2rem;
                        padding: 1rem 0; 
                    }
                `}>{job ? job?.props?.공고명 : division?.부서}</Box>
                    <Box sx={css`
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 3rem;
                    color: #444444;
                    letter-spacing: -1px;
                    @media (max-width: 1320px) {
                        font-size: 1.7rem;
                        line-height: 2.6rem;
                    }
                    @media (max-width: 992px) {
                        font-size: 1.8rem;
                        line-height: 3rem;
                    }
                `}>{job?.props?.마감일자}</Box>
                </Box>

            </Box>

            <Box component="section" sx={css`
                font-size: 1.8rem;
                line-height: 3rem;
                color: #444444;
                margin-bottom: 4rem;
                flex: 1;
                letter-spacing: -1px;
                padding: 1rem 3rem 3rem;
                @media (max-width: 992px) {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0 2rem 2rem;
                    margin-bottom: 4rem;
                }
            `}>
                {(Object.entries(job.jdProps).map(([title, desc]) => {
                    return <Box sx={{
                        color: '#444',
                        my: '20px',
                    }} key={title}>
                        <Box sx={css`
                            font-size: 2.4rem;
                            font-weight: 700;                         
                            line-height: 6.6rem;
                            @media (max-width: 992px) {
                                font-size: 2rem;
                            }
                        `}

                        >{desc && title}</Box>
                        <Box
                            sx={css`
                            font-size: 2rem;
                            font-weight: 400;                         
                            line-height: 4rem;
                            word-break: keep-all;
                            white-space: break-spaces;
                            @media (max-width: 992px) {
                                font-size: 1.6rem;
                            }
                        `

                            }>{desc.trim()}</Box>
                    </Box>;
                }))}</Box>


        </Box>) :
            (<Box className='JobPosting' sx={css`
            background-color: #FFF;
            padding: 5rem 4rem;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            @media (max-width: 992px) {
                padding: 4rem 2rem ;        
            }
        `}>
                <Box component="section" sx={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 1.5rem;
                
                @media (max-width: 992px) {
                    flex-direction: column;
                    align-items: flex-start;
                    
                }
            `}>
                    <Box>
                        <Box sx={css`
                        font-weight: 400;
                        font-size: 2.4rem;
                        line-height: 3.6rem;
                        letter-spacing: -0.5px;
                        color: #111111;
                    `}>{job ? division?.부서 : ''}</Box>
                        <Box sx={css`
                        font-weight: 700;
                        font-size: 3rem;
                        line-height: 4.2rem;
                        letter-spacing: -1px;
                        color: #743FA3;
                    `}>{job ? job?.props?.공고명 : division?.부서}</Box>
                    </Box>
                    <Box sx={css`
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 3rem;
                    color: #444444;
                    letter-spacing: -1px;
                    @media (max-width: 992px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                `}>{job?.props?.마감일자}</Box>
                </Box>

                <Box component="section" sx={css`
                font-size: 1.8rem;
                line-height: 3rem;
                color: #444444;
                margin-bottom: 6rem;
                flex: 1;
                letter-spacing: -1px;
                white-space: break-spaces;
                
                @media (max-width: 992px) {
                    flex-direction: column;
                    align-items: flex-start;
                
                }
            `}>
                    {division?.부서소개}</Box>


                {division?.공고?.length > 0 &&
                    <>
                        <Box component="section" className='title' sx={{
                            fontSize: '2.2rem!important',
                        }}>채용 중인 공고</Box>

                        <Box component="section" sx={
                            css`    
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            gap: 1rem;`
                        }>
                            {
                                division?.공고?.map((job, key) => {

                                    return (<Box key={key} sx={
                                        css`
                                    box-sizing: border-box;
                                    padding: 3rem;
                                    outline: 1px solid #D5D3E4;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: space-between;
                                    cursor: pointer;
                                    @media (max-width: 992px) {
                                        flex-direction: column;
                                        gap: 0.8rem;
                                    }
                                    &:hover {
                                        box-sizing: border-box;
                                        outline: 2px solid #743FA3;
                                        background-color: #fff;
                                        box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.15);
                                        transform: translateY(-3px);
                                        transition: transform 0.2s ease 0s;
                                    }
                                    &:hover > div{
                                        color: #743FA3;
                                        
                                    }
                                    &:hover>div>hr{
                                        background: #1DD1D3;
                                        height: 8px !important;
                                        width: 100%;
                                        border: 0;
                                        padding: 0;
                                        display: block;
                                        mix-blend-mode: multiply;
                                        margin: 0;
                                        bottom: 0;
                                        transition: all 2s;
                                    }

                                `}
                                        onClick={e => {
                                            let url = `/${i18n.language}/Careers/Jobs/${division.pathname}/${job.props.NO}`;
                                            // console.log(url);
                                            to(url);
                                        }}
                                    >
                                        <Box sx={
                                            css`
                                    display: block;
                                    font-weight: 500;
                                    font-size: 2rem;
                                    line-height: 3rem;
                                    color: #111;
                                    position: relative;
                                    `
                                        }>
                                            <Box component="hr" sx={
                                                css`
                                        display: none;
                                        background: #FFF;
                                        position: absolute;
                                    `
                                            }></Box>
                                            <Box sx={
                                                css`
                                        padding: 0 0.1rem;
                                        @media (max-width: 992px) {
                                            text-align: center;
                                        }
                                    `
                                            }>{job.props.공고명}</Box>

                                        </Box>
                                        <Box sx={{
                                            fontSize: '1.6rem',
                                            fontWeight: 400,
                                            lineHeight: '2.6rem',
                                            color: '#111'
                                        }}>{job.props.마감일자}</Box>
                                    </Box>);

                                })
                            }
                        </Box>
                    </>
                }
            </Box>)}


        {/* 중요 */}
        <StepForm key={divisionPathname} {...{ notionData, divisionPathname, division, job, jobNo, to, storeRef }} />
    </>;
}






export default Jobs;