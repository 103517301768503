/** @jsxImportSource @emotion/react */
import {
	useState,
	useMemo,
	useCallback,
	useRef,
	useEffect,
	forwardRef,
	useImperativeHandle
} from 'react';
import { css } from '@emotion/react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import StepCss from './StepCss';

const StepLanguage = forwardRef((props, ref) => {
	// console.log(props.languages)// 구체적인 공고를 클릭하면 languages가 존재한다.
	const isTalentPool = props.languages === undefined;

	const [valid, setValid] = useState(true);
	const [language, setLanguage] = useState(props.language ?? '');
	const [languages, setLanguages] = useState(
		props.languages ?? [
			'현지화 PM',
			'한국어 → 영어 현지화',
			'한국어 → 중문(간체) 현지화',
			'한국어 → 중문(번체) 현지화',
			'한국어 → 태국어 현지화',
			'한국어 → 인도네시아어 현지화',
			'한국어 → 터키어 현지화',
			'한국어 → 독일어 현지화',
			'한국어 → 프랑스어 현지화',
			'한국어 → 스페인어(유럽) 현지화',
			'한국어 → 스페인어(남미) 현지화',
			'한국어 → 포르투갈어(브라질) 현지화',
			'한국어 → 러시아어 현지화',
			'한국어 → 폴란드어 현지화',
			'영어 → 한국어 현지화',
			'중문(간체) → 한국어 현지화',
			'중문(간체) → 영어 현지화',
			'기타 → 한국어 현지화',
			'한국어 → 기타 현지화',
		]
	);
	const [error, setError] = useState('');
	const test = useCallback(
		() => {
			let b = language.length > 0;
			setValid(b);
			setError(b ? '' : '*포지션을 선택하세요.');
			return b;
		},
		// [language]
	);
	const changeLanguage = useCallback((e) => {
		setLanguage(e.target.value);
	}, []);
	const init = useCallback(() => {
		setLanguage('');
		setError('');
	}, []);

	useImperativeHandle(ref, () => ({
		language,
		valid,
		test,
		init
	}));

	useEffect(()=>{
		if(Array.isArray(languages) && languages.length===0){
			setLanguages(['현지화 PM']);
		}
	}, [])


	const cssOption = css`
    height: 50px;
    background-color: white;
    justify-content: center;
  `;
	const cssNone = css`
    display: none;
  `;

	return (
		<div css={StepCss.root}>
			<div css={StepCss.titleRoot}>
				<div css={StepCss.title}>
					{props.title ?? `지원하는 포지션을 선택하세요.`}
				</div>
				<div css={StepCss.titleDesc}>{props.desc}</div>
			</div>
			{/* <select css={cssSelect} value={language} onChange={changeLanguage}>
                <option label="언어" value="" disabled />
                {languages.map((e, i) => {
                    if (typeof e === 'string') {
                        return <option key={i} css={cssOption} label={e} value={e} />;
                    } else if (e && typeof e === 'object') {
                        return <option key={i} css={cssOption} {...e} />;
                    }
                })}
            </select> */}
			<div css={StepCss.fromBox}>
				<div css={StepCss.inputBox}>
					<Select
						css={StepCss.select}
						displayEmpty
						value={language}
						onChange={changeLanguage}
					>
						<MenuItem value="" disabled css={[StepCss.option, StepCss.displayNone]}>
							포지션
						</MenuItem>
						{languages.map((e, i) => {
							if (typeof e === 'string') {
								return (
									<MenuItem key={i} value={e} css={StepCss.option}>
										{e}
									</MenuItem>
								);
							} else if (e && typeof e === 'object') {
								return (
									<MenuItem key={i} {...e} css={StepCss.option}>
										{e.label}
									</MenuItem>
								);
							}
						})}
					</Select>
					<div hidden id="language-select-label" />
					<div css={StepCss.error}>{error}</div>
				</div>

			</div>
		</div>
	);
});

export default StepLanguage;
