/** @jsxImportSource @emotion/react */
import { Link, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { css } from '@emotion/react'

export default function SectionTop(props) {
    const _location = useLocation();

    const isLife = _location.pathname.includes('Life');
    const isJobs = _location.pathname.includes('Jobs');

    let className_page_cover = 'page_cover'
    if(isLife) className_page_cover += ' life'
    if(isJobs) className_page_cover += ' jobs'


    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    return (
        <section className="careers section_top" >
            <div className={className_page_cover}>
                <div className="inner">
                    <h2 className="cont_title">
                        {isLife && 'Life at 2bytes'}
                        {isJobs && 'Jobs at 2bytes'}
                    </h2>
                    <p className="desc">
                        {isLife &&
                            <>
                                <span>한 글자 한 글자 소중히,</span>
                                <span>한 사람 한 사람 소중히.</span>
                            </>}
                        {
                            isJobs && <>
                                <span>투바이트와 함께하실</span>
                                <span>투바이터 분들을 찾습니다.</span>
                            </>
                        }
                        {/* 다국어 */}
                        <span css={css`
                            display: block;
                            white-space: break-spaces;
                        `}>{t('Careers_lang_desc')}</span>
                    </p>
                </div>
            </div>

            <div className="tab_wrap fixed_top">
                <div className="tab_box">
                    <ul className="tab_list">
                        <li className={isLife ? 'on' : ''}>
                            <Link to={`/${i18n.language}/Careers/Life`}>
                                <span className="thumb" />
                                <strong className="text">
                                    Life at 2bytes
                                </strong>
                            </Link>
                        </li>
                        <li className={isJobs ? 'on' : ''}>
                            <Link to={`/${i18n.language}/Careers/Jobs`}>
                                <span className="thumb" />
                                <strong className="text">
                                    Jobs at 2bytes
                                </strong>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
