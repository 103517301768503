import axios from 'axios';

// const baseUrl = `https://v9dxnsj6mc.execute-api.ap-northeast-2.amazonaws.com/default/2bytescorp-notion-retrieve`;
const baseUrl = `https://mz8tek24g2.execute-api.ap-northeast-2.amazonaws.com/production/notion-get-data`;

async function getJobs() {
    let { data } = await axios.get(`${baseUrl}?id=1`);
    return parser1({ text: { results: data } });
}

async function getDivisions() {
    let { data } = await axios.get(`${baseUrl}?id=2`);
    return parser2({ text: { results: data } });
}

async function getBlogs() {
    let { data } = await axios.get(`${baseUrl}?id=3`);
    return parserBlogList({ text: { results: data } });
}


function parser1(fetchResponse) {
    const {
        statusCode,
        text: { results },
    } = fetchResponse;
    return results
        .map(({ properties: s }) => {
            const rs = { props: {}, jdProps: {} };
            for (let k in s) {
                if (/^JD/i.test(k)) {
                    let v = simpleValueParser(s[k], k);
                    k = k.replace(/^JD[^-]+-/i, '').trim();
                    rs.jdProps[k] = v;
                } else {
                    let v = simpleValueParser(s[k], k);
                    rs.props[k] = v;
                }
            }

            // JD항목 순서 바로 잡기
            {
                const result = {};
                const order = { length:0 };
                const other = [];
                const list = [
                    '담당업무',
                    '모집언어',
                    '자격요건',
                    '필요역량',
                    '우대사항',
                    '근무조건',
                    '채용절차',
                ];
                for (let k in rs.jdProps) {
                    let is = false;
                    for (let i = 0; i < list.length; i++) {
                        let source = list[i];
                        let regExp = new RegExp(source);
                        is = regExp.test(k.replace(/\s/g, ''));
                        if (is) {
                            order[i] = k;
                            order.length ++;
                            break;
                        }
                    }
                    if(is==false){
                        other.push(k);
                    }
                }
                Array.from(order).filter(e=>e!==undefined).forEach(k => result[k] = rs.jdProps[k]);
                other.forEach(k => result[k] = rs.jdProps[k]);
                rs.jdProps = result;
                // console.log(result, order, other);
            }

            makeupLangsets(rs);
            return rs;
        })
        .filter(e => e.props.공개여부)
        .sort((a, b) => parseInt(a.props.NO) < parseInt(b.props.NO) ? -1 : 1);
}


function countDivisions(data, counter) {
    return data.reduce((r, e) => {
        const {
            NO,// "1"
            공개여부,// true
            공고명,// "게임 Localization 전문가 정규직 (상시모집)"
            마감일자,// "상시모집"
            부서,// "글로벌라이제이션센터"
            // 즉시 지원 양식,// "4"
        } = e.props;

        if (r.hasOwnProperty(부서)) {
            r[부서]++;
        } else {
            r[부서] = 1;
        }

        return r;
    }, Object.assign({}, counter));

}



function makeupLangsets(o) {
    let k = Object.keys(o.jdProps).find((k) => /^모집언어$/.test(k.replace(/\s+/g, ''))); // 타겟: JD 항목 - 모집 언어
    let v = o.jdProps[k];
    if (typeof v === 'string' && v.trim().length) {
        o.langsets = v
            .split('\n')
            .filter((e) => e.includes('◦'))
            .map((e) => {
                let langset = e.replace('◦', '').trim();
                if (!langset.includes('→')) langset = '한국어 → ' + langset;
                return langset;
            });
    }
}

function parser2(fetchResponse) {
    const {
        text: { results },
    } = fetchResponse;

    return results
        .map(({ properties: s }) => {
            const rs = {};
            for (let k in s) {
                let v = simpleValueParser(s[k], k);
                rs[k] = v;
            }
            return rs;
        })
        .sort((a, b) => parseInt(a.NO) < parseInt(b.NO) ? -1 : 1);
}

function parser3(fetchResponse) {
    const {
        text: { results },
    } = fetchResponse;
    return results.map(({ properties: s }) => {
        const arr = [];
        for (let k in s) {
            let v = valueParser(s[k], k);
            push(v, k, arr);
        }
        return arr;
    });
}

function parserBlogList(fetchResponse) {
    const {
        statusCode,
        text: { results },
    } = fetchResponse;
    return results.map(({ properties: s }) => {
        const rs = {};
        for (let k in s) {
            rs[k] = simpleValueParser(s[k]);
        }
        return rs;
    });
}

function publicFilter(data) {
    // console.log(data[0].normals)
    return data.filter((e) => e.normals.some(([k, v]) => k === '공개여부' && v === true));
}

function languageFilter(data) {
    return data;
}

function push(v, k, arr) {
    if (Array.isArray(v)) {
        if (v.length === 1) {
            arr.push([k, v[0]]);
        } else if (v.length > 1) {
            arr.push([k, v]);
        }
    } else if (v !== null) {
        arr.push([k, v]);
    }
}

function valueParser(obj, key) {
    const { type } = obj;
    const data = obj[type];

    switch (type) {
        case 'rich_text':
        case 'text':
        case 'title':
            return data.map((e) => {
                const text = e.plain_text;
                const style = Object.entries(e.annotations).filter(
                    ([k, v]) => v !== false && v !== 'default'
                );
                const { href } = e;
                if (!href && style.length === 0) return text;
                return { text, style, href };
            });
        case 'relation':
            return null;
        case 'checkbox':
            return data;
        default:
            console.log('!!!!original', type, data, key);
            return data;
    }
}

function simpleValueParser(obj, key) {
    const { type } = obj;
    const data = obj[type];
    // console.log(type, data )

    switch (type) {
        case 'rich_text':
        case 'text':
        case 'title':
            return data.map((e) => e.plain_text).join('');
        case 'relation':
            return null;
        case 'checkbox':
        case 'url':
            return data;
        default:
            console.log('!!!original', type, data, key);
            return data;
    }
}




function groupDivisions(divisions, jobs) {
    const divisionsGroup = divisions.reduce((r, e) => {
        e.공고 = [];
        // 노션 부서 테이블에서 *로 시작하는 이름을 *없애기
        for (let k in e) {
            if (/^\*/.test(k)) {
                let v = e[k];
                e[k.slice(1).trim()] = v;
                delete e[k];
            }
        }
        r.push(e);
        return r;
    }, []);
    return jobs.reduce((r, job) => {
        let k = job.props.부서;

        let row = divisionsGroup.find(e => e.부서 == k);
        if (row && Array.isArray(row.공고)) {
            row.공고.push(job);
        }
        return r;
    }, divisionsGroup);
}

Object.defineProperty(window, 'careers', {
    value: {
        checkList: [],
        senderParams: {},
        resetSenderParams() {
            let o = this.senderParams;
            for (let k in o) {
                if (!['dbId', 'dataSet'].includes(k)) {
                    delete o[k];
                }
            }
        },
        resetCheckList() {
            this.checkList = [];
        }
    }
});

export default {
    getJobs,
    getDivisions,
    getBlogs,
    groupDivisions,
};