/** @jsxImportSource @emotion/react */
import {
    useState,
    useMemo,
    useCallback,
    useRef,
    forwardRef,
    useImperativeHandle
} from 'react';
import { css } from '@emotion/react';
import StepCss from './StepCss';

const StepNationalityResidenceStatus = forwardRef((props, ref) => {
    const [nationalityValid, setNationalityValid] = useState(true);
    const [nationality, setNationality] = useState(props.nationality ?? '');
    const [nationalityError, setNationalityError] = useState('');
    const [residenceStatus, setResidenceStatus] = useState(
        props.residenceStatus ?? ''
    );
    const testNationality = useCallback(() => {
        let b = nationality.length > 0 && /^[가-힣]+$/.test(nationality);
        setNationalityValid(b);
        setNationalityError(b ? '' : '*한글로 국적을 입력하세요.');
        return b;
    }, [nationality]);

    const test = useCallback(() => {
        return testNationality();
    }, [nationality]);
    const changeNationality = useCallback((e) => {
        setNationality(e.target.value.replace(/\s+/g,''));
    }, []);
    const changeResidenceStatus = useCallback((e) => {
        // 조건 20자 이내, 모든 글자
        // setResidenceStatus(e.target.value.replace(/[^a-z0-9-]+/gi, '').replace(/-{2,}/g,'-').trim());
        setResidenceStatus(e.target.value.slice(0, 20));
    }, []);

    useImperativeHandle(ref, () => ({
        nationality,
        setNationality,
        nationalityValid,
        residenceStatus,
        setResidenceStatus,
        test
    }));

    return (
        <div css={StepCss.root}>
            <div >
                <div css={StepCss.title}>국적과 체류 자격을 작성하세요.</div>
                <div css={StepCss.titleDesc}>
                    *한국 거주 외국인의 경우 체류 자격을 필수로 작성해주세요.
                </div>
            </div>
            <div css={StepCss.fromBox}>
                <div css={StepCss.content2ea}>
                    <div css={StepCss.inputBox}>
                        <input
                            css={StepCss.input}
                            type="text"
                            value={nationality}
                            onChange={changeNationality}
                            placeholder="국적"
                        />
                        <div css={StepCss.error}>{nationalityError}</div>
                    </div>
                    <div css={StepCss.inputBox}>
                        <input
                            css={StepCss.input}
                            type="text"
                            value={residenceStatus}
                            onChange={changeResidenceStatus}
                            placeholder="체류자격 (선택)"
                        />
                    </div>
                </div>

            </div>
           
        </div>
    );
});

export default StepNationalityResidenceStatus;
