import axios from 'axios';

/* 
GET https://mz8tek24g2.execute-api.ap-northeast-2.amazonaws.com/production/notion-post-data
  PARAMS? dbId = 노션 내 부서별 DB 아이디
        & dataSet = 노션 내 부서별 DB dataSet 
        & notice = 공고명
        & applicantName = 이름
        & email = 이메일
        & phoneNumber = 전화번호
        & myIntroductionFileURL = 이력서 파일 url
        & myPortfolioFileURL = 포트폴리오 파일 url
        & myPortfolioURL = 포트폴리오 url 
        & language = 지원자 언어
        & nationality = 국적
        & visa = 체류자격
  
  
  Response:
  { 
     "object": "list",
     "results": [...]
  } 
  
  Notion database: https://www.notion.so/2bytes/Career-1841a8ce310446c48125791cc6de9cd8

*/

// 호출 시, 반드시 사용하지 않는 값도 보내야 한다는 룰이 있었습니다.
function formatParams(params) {
    const keys = [
        'dbId',
        'dataSet',
        'notice',
        'applicantName',
        'email',
        'phoneNumber',
        'myIntroductionFileURL',
        'myPortfolioFileURL',
        'myPortfolioURL',
        'language',
        'nationality',
        'visa',
    ];
    keys.forEach(k => {
        if (params[k] === undefined || params[k]==='') {
            params[k] = '-';
        }
    });
    return params;
}

export default function send(params) {

    params = formatParams(params);

    return axios
        .get(
            'https://mz8tek24g2.execute-api.ap-northeast-2.amazonaws.com/production/notion-post-data',
            {
                params,
            }
        )
        .then((res) => {
            if (res.status === 200) {
                const title = '정상적으로 접수되었습니다!';
                const content = '제출하신 내용 확인 후 이메일로 연락 드리겠습니다. 감사합니다.';
                swal(title, content, 'success');
            } else {
                const title = '메시지 전송 중 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.';
                const content = '';
                swal(title, content, 'error');
            }
            console.log(res);
            return res;
        })
        .catch((err) => {
            const title = '메시지 전송 중 오류가 발생했습니다. \n다시 시도해 주시기 바랍니다.';
            const content = '';
            swal(title, content, 'error');
            console.log(err);
            return err;
        });
}
