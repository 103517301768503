/** @jsxImportSource @emotion/react */
import {
    useState,
    useCallback,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { css } from '@emotion/react';
import StepCss from './StepCss';

const StepName = forwardRef((props, ref) => {
    const [valid, setValid] = useState(true);
    // const [name, setName] = useState(props.name ?? careers?.senderParams?.applicantName ?? '');
    const [name, setName] = useState(props.name ?? '');
    const [error, setError] = useState('');
    const test = useCallback(
        (e) => {
            let b = name.length > 0;
            setValid(b);
            setError(b ? '' : '*이름을 입력하세요.');
            return b;
        },
        [name]
    );
    const changeName = useCallback((e) => {
        setName(e.target.value);
    }, []);

    useImperativeHandle(ref, () => ({
        name,
        setName,
        valid,
        test
    }));

    return (
        <div hidden={props.hidden} css={StepCss.root}>
            <div>
                <div css={StepCss.title}>{props.title ?? '이름을 작성하세요.'}</div>
                <div css={StepCss.titleDesc}>{props.desc}</div>
            </div>
            <div css={StepCss.fromBox}>
                <div css={StepCss.inputBox}>
                <input
                        css={StepCss.input}
                        type="text"
                        value={name}
                        onChange={changeName}
                        placeholder="이름"
                    />
                    <div css={StepCss.error}>{error}</div>
                </div>
            
            </div>
        </div>
    );
});

export default StepName;
