import call from './call';
/* 
https://hooks.slack.com/workflows/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
  POST
  Body: {
    "notice": "Example text",
    "applicantName": "Example text",
    "email": "Example text",
    "phoneNumber": "Example text",
    "myIntroductionFileURL": "Example text",
    "myPortfolioFileURL": "Example text",
    "myPortfolioURL": "Example text"
  } 
*/
export default function (params) {
    return call('https://hooks.slack.com/workflows/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', params)
    // {
    //     notice,
    //     applicantName,
    //     email,
    //     phoneNumber,
    //     myIntroductionFileURL,
    //     myPortfolioFileURL,
    //     myPortfolioURL,
    // }
}