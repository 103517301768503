import React, { useState, forwardRef, useImperativeHandle } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const ContactAlertLayer = forwardRef(({ _ }, ref) => {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    const [display, setDisplay] = useState(false);

    //useImperativeHandle을 통해 Contact.js(부모) 에서 아래 함수 사용가능
    useImperativeHandle(ref, () => {
        return {
            openModal: () => open(),
            close: () => close()
        };
    });

    //팝업 오픈 함수
    const open = () => {
        $('body').css("overflow", "hidden");
        setDisplay(true);
    };

    //팝업 클로즈 함수
    const close = (e) => {
        $('body').css("overflow", "");
        e.preventDefault();
        e.stopPropagation();
        setDisplay(false);
    };

    return (
        display &&
        ReactDOM.createPortal(
            <div className="layer_wrap" style={{ display: 'block' }}>
                <div className="layer_popup alert_popup open">
                    <div className="layer_body">
                        <div className="cont">
                            {pt('ContactAlertLayer_1')}
                            {/* 회사소개서가
                            <br />
                            다운로드 되었습니다. */}
                        </div>
                        <div className="btn_area">
                            <button rel="noreferrer" className="btn_alert" onClick={close}>
                                {pt('ContactAlertLayer_2')}
                                {/* 확인 */}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="dim" onClick={close}></div>
            </div>,
            document.getElementById('contact_alert_layer')
        )
    );
});
export default ContactAlertLayer;
