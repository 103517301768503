
import { Link, Outlet } from 'react-router-dom';

const WithoutTopMenuI18nLayout = () => {

    return (
        <div id="wrap" >
            <header id="header" className={`$openMenu boder-bottom-1`} >
                <div className="header_wrap">
                    <div className="inner">
                        <h1 className="h_logo">
                            <Link to={`/`}>2Bytes byte the world</Link>
                        </h1>
                    </div>
                </div>
            </header>
            <Outlet />
            <footer id="footer">
                <div className="f_bottom boder-0">
                    <div className="inner clear">
                        <p className="f_left copyright">
                            © Copyright 2bytes Corp. All Rights Reserved.
                            <br />
                            <Link to={`Privacy`} className="privacyBtn" target="_blank">
                                개인정보처리방침
                            </Link>
                        </p>

                        <div className="f_right sns">
                            <a
                                className="facebook"
                                target="_blank"
                                href="https://www.facebook.com/2bytescorp"
                                rel="noreferrer"
                            >
                                <i className="facebook" />
                            </a>
                            <a
                                className="youtube"
                                target="_blank"
                                href="https://www.youtube.com/channel/UCmK0q-ApIEUODuyS1h-owdg"
                                rel="noreferrer"
                            >
                                <i className="youtube" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer >
        </div >

    );
};

export default WithoutTopMenuI18nLayout;