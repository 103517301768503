/** @jsxImportSource @emotion/react */
import {
    useState,
    useCallback,
    forwardRef,
    useImperativeHandle
} from 'react';
import StepCss from './StepCss';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';


function ellipsis(v, len = v.length) {
    if(v.length > len) {
        v = v.slice(0, Math.floor(len / 2) - 2) + '...' + v.slice(-(Math.floor(len / 2) - 2));
    }
    return v;
}

const StepLastCheck = forwardRef((props, ref) => {
    const [valid, setValid] = useState(true);
    const [check, setCheck] = useState(false);
    const test = useCallback(() => {
        setValid(check);
        return check;
    }, [check]);
    const changeCheck = useCallback((e) => setCheck(e.target.checked), [check]);
    const { i18n } = useTranslation();

    useImperativeHandle(ref, () => ({
        check,
        setCheck,
        test,
        isLastCheckStep: true
    }));

    // console.log(careers)

    return (
        <div css={StepCss.root}>
            <div css={StepCss.titleRoot}>
                <div css={StepCss.title}>
                    {props.title ?? '최종 제출 전 작성하신 내용을 확인해주세요.'}
                </div>
                <div css={StepCss.titleDesc}>{props.desc}</div>
            </div>
            <div css={StepCss.fromBox}>
                <div css={StepCss.listBox}>
                    <div css={StepCss.list}>
                        {careers.checkList?.map(([k, v], i) => (
                            <div key={i} css={css`
                                overflow: hidden;
                                text-overflow: ellipsis;
                            `}>
                                {i + 1}. {k}: {ellipsis(v, 50)}
                            </div>
                        ))}
                    </div>
                    <div  >
                        <div css={StepCss.checkText}>
                            *최종 지원 시, 위 모든 내용이 자동으로 제출됩니다.
                        </div>
                        <div css={[StepCss.checkText2, valid || check ? undefined : StepCss.errorColor]}>
                            <a css={[StepCss.checkAnchor, valid || check ? undefined : StepCss.errorColor]} href={`/${i18n.language}/Privacy`} target="_blank">
                                개인 정보 이용 및 수집에 대한 약관
                            </a>
                            을 확인하였으며, 이에 동의합니다.
                            <input
                                type="checkbox"
                                value={check}
                                onChange={changeCheck}
                                style={{
                                    width: '16px',
                                    height: '16px',
                                    outline: valid ? '': '2px solid #B3261E',
                                }}
                            />
                        </div>
                    </div>

                </div>
              
            </div>
        </div>
    );
});

export default StepLastCheck;
