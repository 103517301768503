import React, { useEffect } from 'react';

function Notice() {

    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);

    // 노출 기간 :  2023년 3월 16일(목) 오전 중 ~ 2023년 3월 31일(금) 오전 중

    return (
        <div id="container" className="cont_nt">
            <section className="section">
                <div className="inner">
                    <div className="title-box">
                        <h2>
                        기준일 및 주식명의개서 정지 공고
                        </h2>
                        <p>
                        상법 제354조 및 정관 제15조의 규정에 의거하여 2023년 9월 16일 현재 주주명부에 기재되어 있는 
주주에게 의결권을 부여하며, 권리주주의 확정을 위해 2023년 9월 16일부터 2023년 9월 16
일까지 주식의 명의개서와 주주명부 기재사항의 변경을 정지함을 공고합니다.

                        </p>
                    </div>

                    <div className="dl_box">
{/* 
                        <dl>
                            <dt>
                                1. 신주식의 종류와 수 : 전환상환우선주식 28,445주
                            </dt>
                        </dl>

                        <dl>
                            <dt>
                                2. 1주의 발행가액 : 금35,156원(액면가 500원)
                            </dt>

                        </dl>
                        <dl>
                            <dt>
                                3. 납입기일 : 2023년 8월 4일
                            </dt>
                        </dl>
                        <dl>
                            <dt>
                                4. 신주식의 인수방법 : 정관 제9조 2항 2호에 의하여 제3자에게 배정 함
                            </dt>
                        </dl>
                        <dl>
                            <dt>
                                5. 제3자배정 유상증자와 관련하여 납입기일은 변동 가능하며, 대표이사에게 위임 함
                            </dt>
                        </dl> */}
                        <div >
                            <p className="footer-text">
                                2023년 09월 01일
                                <br />
                                <br />
                                투바이트 주식회사<br />
                                경기도 안양시 동안구 시민대로 327번길 24, 6층, 7층, 8층(관양동)<br />
                                대표이사 함영철<br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Notice;
