import { useEffect, useState, useRef } from 'react';
import React from 'react';
import Why2Bytes from '../layout/Why2Bytes';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import BlogYoutube from '../resources/images/dummy/img_youtube_thumb.png';
import BlogYoutube640_30 from '../resources/images/dummy/img_youtube_thumb_640x30.png';
import SectionBottom from '../layout/SectionBottom';
import PortfolioCard from './PortfolioCard';
import Blog from '../layout/Blog';
import arrow from '../resources/images/arrow-right-green.svg';
// import { Swiper, SwiperSlide } from 'swiper/react'

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';


function Home() {
    const isMountedRef = useRef(false);
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));
    const youtubeId = i18n.language === 'ko' ? 'r64PiDTXDjk' : 'LFgeWPKmsjs';

    // const tn = (v) => {
    //     return i18n.t(v).split(/(\n)/).map((e, i) => e === '\n' ? <br key={i} /> : e);
    // };

    const rootUrl = '/static/img/portfolio/';
    const [pfList, setPfList] = useState([]);

    useEffect(() => {
        axios
            .get(t('url_portfolio_list'))
            .then(({ data }) => {
                return data.map((item) => {
                    item.companyImg = rootUrl + item.companyImg;
                    item.thumbImg = rootUrl + item.thumbImg;
                    return item;
                });
            })
            .then((list) => {
                setPfList(list);
                if (isMountedRef.current === false) {
                    jqueryInit();
                    isMountedRef.current = true;
                }
            });
    }, [i18n.language]);

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const pageLink = (page) => {
        const currentPath = pathname.trim().split('/')[3];
        console.log(page, currentPath);
        if (page === currentPath) {
            $(window).scrollTop(0);
        } else if (page === "Contact") {
            navigate(`/${i18n.language}/Contact`);
        }
        else if (page === "Blog") {
            navigate(`/${i18n.language}/Portfolio`);
        }
        else if (page === "Why2Bytes") {
            navigate(`/${i18n.language}/Why2Bytes`);
        }
    };

    const jqueryInit = () => {
        $('.open_modal').YouTubePopUp();

        var $wrap = $('.portfolio_list_items'),
            wrapWid = $wrap.width(),
            $items = $wrap.length ? $wrap.find('.list_items') : '',
            $item = $wrap.length ? $wrap.find('.item') : '',
            itemsLen = $wrap.length ? $item.length : 0,
            itemWid = $wrap.length ? $items.find('li.item').outerWidth() : 0;
        // console.log($wrap.length, $item.length);
        var transX = 0,
            cloneItems = [],
            cloneItem;

        var slideInterval = 0;

        var cloneLen = Math.floor(wrapWid / itemWid);
        for (let i = 0; i < cloneLen + 1; i++) {
            cloneItem = $item.eq(i).clone().removeClass('real').addClass('clone');
            cloneItems.push(cloneItem);
            $items.append(cloneItems[i]);
        }
        transX = $items.css('left');

        function slide() {
            $items.css('left', -transX + 'px');
            if (
                transX <=
                itemWid * (itemsLen + cloneItems.length - 1) - wrapWid + 62
            ) {
                transX++;
            } else {
                transX = 0;
            }
            slideInterval = setTimeout(slide, 30);
        }

        clearTimeout(slideInterval);
        slideInterval = setTimeout(slide, 30);

        $('.portfolio_list_items .item').on('mouseenter', function () {
            clearTimeout(slideInterval);
            slideInterval = 0;
        });
        $('.portfolio_list_items .item').on('mouseleave', function () {
            clearTimeout(slideInterval);
            slideInterval = setTimeout(slide, 30);
        });

        $('.portfolio_list_wrap .next')
            .off('click')
            .on('click', function () {
                clearTimeout(slideInterval);
                slideInterval = 0;
                var prevItemLen = Math.floor(transX / itemWid);
                var calc1 = (prevItemLen + Math.floor(wrapWid / itemWid)) * itemWid;
                var calc2 = itemWid * (itemsLen + cloneItems.length - 1) - wrapWid + 62;
                var newTransX = calc1 < calc2 ? calc1 : calc2;
                $items.stop().animate({ left: -newTransX + 'px' }, 1000, function () {
                    transX = newTransX;
                    slideInterval = setTimeout(slide, 30);
                });
            });
    };

    return (
        <div id="container">
            {/* <!-- visual --> */}
            <div className="visual_wrap">
                <div className="movie_inner">
                    <div className="video_wrap">
                        <div className="swiper-img-1" />
                        {/* <div className="swiper-img-2" /> */}
                    </div>
                    <div className="cont_box">
                        <div className="inner">
                            <dl>
                                <dd className='cont_title'>
                                    <div className="hidden_sm">{pt('Home_1')}</div>
                                    {/* <div className="hidden_sm">
                                        Byte the World,
                                    </div> */}
                                    <div className="visible_sm">{pt('Home_1_m')}</div>
                                    {/* <div className="visible_sm">
                                        글로벌 게임 시장으로 향하는 빠른 길
                                    </div> */}
                                </dd>
                                <dd>
                                    <button onClick={() => pageLink("Contact")} className="btn_home btn_primary">
                                        {pt('Home_contact')}
                                        {/* 자세히 보기 */}
                                    </button>
                                    <button onClick={() => pageLink("Blog")} className="btn_home btn_primary_inversion">
                                        {pt('Home_portfolio')}
                                        {/* 자세히 보기 */}
                                    </button>
                                </dd>
                            </dl>
                            {/* <!-- <a href="javascript:void(0);" className="btn_basic btn_lg">버튼버튼</a> --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- // visual --> */}

            <section className="section_intro">
                <div className="inner">
                    <div className="left">
                        <p>
                            {pt('Home_2')}
                            {/* 작은 차이로 달라지는 게임 서비스 품질. */}
                            <br className='hidden_sm'/>
                            <span>
                                {pt('Home_3')}
                                {/* 피땀 흘려 개발한 게임이 빛을 볼 수 있도록 한 팀처럼 일합니다. */}
                            </span>
                        </p>
                        <p>
                            <strong>
                                {pt('Home_4')}
                                {/* 투바이트는 게임 글로벌 런칭을 위한 최적의 파트너입니다. */}
                            </strong>
                        </p>
                        <div className="player">
                            <a
                                href={`https://www.youtube.com/watch?v=${youtubeId}&amp;t=0s&amp;autoplay=1&amp;mute=1`}
                                data-href={`https://www.youtube.com/watch?v=${youtubeId}&amp;t=0s&amp;autoplay=1&amp;mute=1`}
                                className="open_modal"
                            >
                                <span className="thumb hidden_sm">
                                    <img src={BlogYoutube} alt="" />
                                </span>
                                <span className="thumb visible_sm">
                                    <img src={BlogYoutube640_30} alt="" />
                                </span>
                                <p className="text">
                                    {pt('Home_5')}
                                    {/* 2bytes를 소개합니다. */}
                                </p>
                            </a>
                        </div>
                        
                    </div>
                    <button onClick={() => pageLink("Why2Bytes")} className="btn_primary_linear">
                        {pt('Home_7')}
                        <img src={arrow} alt="Arrow image" className = "arrow"/>
                    </button>
                </div>
            </section>

            {/* <!-- blog --> */}
            <Blog />
            {/* <!-- // blog -->

            {/* why 2bytes? */}
            <Why2Bytes />
            {/* // why 2bytes? */}

            {/* <!-- portfolio --> */}
            <section className="section_portfolio hidden_sm">
                <div className="inner">
                    <div className="h_title_box">
                        <h3 className="h_title">Portfolio</h3>
                    </div>
                    <div className="portfolio_list_wrap">
                        <div className="portfolio_list_items">
                            <ul id="portfolio_slider" className="list_items">
                                {pfList.map((item, index) => {
                                    let supports = item.supports.split('\n');
                                    let languages = item.languages.split(/\s*\n\s*/g).filter(e => e.trim()).map((e) => ' ' + e);
                                    return (
                                        <li className="item" key={index}>
                                            <div className="card">
                                                <div className="thumb">
                                                    <img
                                                        src={item.thumbImg}
                                                        style={{ width: '100%' }}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="info">
                                                    <div className="box">
                                                        <div className="top">
                                                            <p className="corp">{item.corp}</p>
                                                            <p className="title">{item.title}</p>
                                                        </div>
                                                        <div className="mid">
                                                            <div className="flx_1">
                                                                <span className="type">{item.type}</span>
                                                                <div className="supports">
                                                                    {supports.map((item, index) => {
                                                                        return (
                                                                            <i
                                                                                className={`icon_${item}`}
                                                                                key={item + index}
                                                                            >
                                                                                {item}
                                                                            </i>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bottom">
                                                            <p className="part">{item.part}</p>
                                                            <ul>
                                                                {languages.map((item, index) => {
                                                                    return <li key={item + index}>{item}</li>;
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="company">
                                                <img src={item.companyImg} alt="" />
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <span className="next" />
                    </div>
                    <div className="btn_area ">
                        <Link
                            to={`Portfolio`}
                            className="btn_basic btn_lg bd_basic font-weight-500"
                        >
                            {pt('Home_showAll')}
                            {/* 전체보기 */}
                        </Link>
                    </div>
                </div>
            </section>
            <section className="section_portfolio visible_sm">
                <div className="inner">
                    <div className="h_title_box">
                        <h3 className="h_title">Portfolio</h3>
                    </div>
                    <div className="portfolio_list_wrap">
                        <div className="m_portfolio_list_items home_portfolio">
                            <ul className="list_items">
                                {pfList.slice(0, 6).map((item, index) => {
                                    return (
                                        <PortfolioCard key={index} item={item}></PortfolioCard>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="btn_area ">
                        <Link
                            to={`Portfolio`}
                            className="btn_basic btn_lg bd_basic font-weight-500 col1_sm"
                        >
                            {pt('Home_showAll')}
                            {/* 전체보기 */}
                        </Link>
                    </div>
                </div>
            </section>
            {/* <!-- portfolio --> */}

            <SectionBottom />
        </div>
    );
}

export default Home;
