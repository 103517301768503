import React, { useEffect } from 'react';

function Notice() {

    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);

    return (
        <div id="container" className="cont_nt">
            <section className="section">
                <div className="inner">
                    <div className="title-box">
                        <h2>임시주주총회 소집 공고</h2>
                        <p>
                            주주님의 건승과 댁내의 평안을 기원합니다. <br />
                            당사는 정관 제19조 및 제21조에 근거하여 임시주주총회를 아래와 같이 소집 공고 하오니 참석하여주시기 바랍니다.<br /><br />
                        </p>
                    </div>

                    <div className="dl_box">
                        <dl>
                            <dt>1. 일 시 : 2024년 11월 08일(금) 오전 11시</dt>
                        </dl>
                        <dl>
                            <dt>2. 장 소 : 경기도 안양시 동안구 시민대로 327번길 24, 본사 7층 회의실</dt>
                        </dl>
                        <dl>
                            <dt>
                                3. 회의목적사항
                            </dt>
                            <dd>
                                <dl>
                                    <dt>
                                        1) 부의안건
                                    </dt>
                                    <dd >
                                    제1호 의안 : 정관 일부 변경의 건
                                    </dd>
                                </dl>
                            </dd>
                        </dl>
                        <dl>
                            <dt>4. 의결권 대리행사 관련 사항</dt>
                            <dd>
                                <dl>
                                    <dt style={{position: 'relative', paddingLeft: '20px'}}><span style={{position: 'absolute', left: '0px', color: '#111'}}>1)</span> 금번 당사의 주주총회는 주주님께서 참석하시어 의결권을 직접 행사하시거나 또는 위임장에 의거 의결권을 간접 행사하실 수 있습니다. 위임장을 작성하신 후 회사로 우편 송부하여 행사하실 수 있습니다.</dt>
                                </dl>
                                <dl>
                                    <dt>2) 위임장 보내실 곳</dt>
                                    <dd>경기도 안양시 동안구 시민대로 327번길 24, 6층<br />투바이트(주) 경영지원실 주주총회 담당자 앞 (우편번호 14055)</dd>
                                </dl>
                            </dd>
                        </dl>
                        <dl>
                            <dt>5. 주주총회 참석시 준비물</dt>
                            <dd>
                                <dl>
                                    <dt>1) 직접행사: 신분증</dt>
                                </dl>
                                <dl>
                                    <dt>2) 대리행사: 위임장(주주와 대리인의 인적사항 기재, 인감 날인), 주주의 인감증명서 1부, 대리인의 신분증</dt>
                                </dl>
                            </dd>
                        </dl>

                        <dl style = {{textAlign: 'center'}}>
                            <dt>
                                <br /><br />2024년 10월 24일
                            </dt>

                        </dl>

                        <div >
                            <p className="footer-text">
                                <br />
                                투바이트 주식회사<br />
                                경기도 안양시 동안구 시민대로 327번길 24, 6층, 7층(관양동)<br />
                                대표이사 함영철<br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Notice;
