import React, { useEffect, useRef, useState } from 'react';

import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

import Skeleton from '@mui/material/Skeleton';
import preloader from '../resources/images/preloader.gif';

 function PreLoader(props) {
    
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log(props.notionData);
        if(props.notionData.divisions.length > 0){
            setLoading(false);
        }
    }, [props.notionData]);


    return (
        <>
         <Fade in={loading}><Box
        component={'div'}
        sx={css`
        display: flex;
        position: fixed;
        top: 0!important;
        left: 0!important;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: #FFF;
        padding: 1em;
        transition: all 0.6s ease-out;
        opacity: 1;
        line-height: 1;
        animation-fill-mode: both;
        animation-duration: .5s;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        user-select: none;
        will-change: opacity;
        z-index: 1000;`} >
            
             <img src={preloader} width={'45'}  />
            
        </Box></Fade>
        </>
    );
}

export default PreLoader;