import React, { useEffect } from 'react';
import SectionTop from '../layout/Why2BytesSectionTop';
import Why2Bytes from '../layout/Why2Bytes';
import Services from '../components/Services';
import SectionBottom from '../layout/SectionBottom';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

import dot_horizontal_bottom from '../resources/images/dot_horizontal_bottom.png';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

function Content() {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    useEffect(() => {
        $('.open_modal').YouTubePopUp();
        $(window).scrollTop(0);
        $(window)
            .on('scroll', function () {
                scrollFn();
            })
            .trigger('scroll');

        function scrollFn() {
            var winHeight = $(window).height();
            if ($('.scrtFn').length) {
                $('.scrtFn').each(function (i) {
                    var $this = $(this),
                        $closest = $this.closest('li[class*=step]'),
                        contHeight = $closest.outerHeight(),
                        offsetT = $this.offset().top,
                        closestT = $closest.offset().top;

                    if (
                        $(window).scrollTop() > offsetT - (winHeight / 2 + 60) &&
                        $(window).scrollTop() <= closestT + (contHeight / 2 - 160)
                    ) {
                        $(this).addClass('on');
                    } else if (
                        $(window).scrollTop() > closestT + (contHeight / 2 - 150) ||
                        $(window).scrollTop() <= offsetT - (winHeight / 2 + 60)
                    ) {
                        $(this).removeClass('on');
                    }
                });
            }
        }
    }, []);
    return (
        <>
            <section className="section_discript">
                <div className="inner">
                    <strong className="title">
                        {pt('ForStages_1')}
                        {/* 언제 2bytes를 만나면 <br className="visible_sm" />
                        좋을까요? */}
                    </strong>
                    <div className="desc purple">
                        {pt('ForStages_2')}
                        {/* 게임 개발 결정에서 런칭까지는 매우 긴 여정입니다. 더 어려운 점은
                        정답이 없다는 점입니다.
                        <br />
                        공통적으로 경험하는 단계를 4개로 나눠 체크할 내용을 모았습니다.
                        <br />
                        각 단계에서 해야 할 일을 놓치면 나중에 비용과 시간 비용으로 돌아오게
                        됩니다.
                        <br />
                        <br />
                        개발에 집중하기만 해도 바쁜데, 잘 알지 못하는 분야인데 꼭 해야 할
                        일들이 꽤 많습니다.
                        <br />
                        그래서 파트너가 중요합니다. 게임을 잘 아는 전문가면서 한 팀처럼 일할
                        수 있는 파트너가 필요합니다.
                        <br />
                        2bytes는 글로벌 런칭 경험에서 쌓은 노하우를, 개발사가 성공하는
                        게임을 개발하도록 공유드리고 싶습니다.
                        <br />
                        게임 개발 각 단계에서 투바이트와 어떤 일을 할 수 있을지 확인해
                        보세요. */}
                    </div>
                </div>
            </section>

            <section className="section_progress fg">
                <div className="inner">
                    <ol className="step_list_wrap">
                        <li className="left step1">
                            <div className="h_title_box">
                                <strong className="h_title">
                                    {pt('ForStages_3')}
                                    {/* <span className="num">1</span>제안&amp;프로토타입 단계
                                    <small className="en">Pre-Production</small> */}
                                </strong>
                            </div>

                            <div className="step_cont">
                                <p className="text">
                                    {pt('ForStages_4')}
                                    {/* 게임 컨셉 핵심을 담은 디자인 트리트먼트 혹은 One-page
                                    proposal를
                                    <br />
                                    통해 게임을 개발하기로 의사결정되었나요? 이제 시작입니다.
                                    <br />
                                    제안 &amp; 프로토타입 단계에서 작성하고 확인할 내용입니다. */}
                                </p>
                                <ul>
                                    <li>
                                        {pt('ForStages_5')}
                                        {/* 아이디어 스케치 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_6')}
                                        {/* 프로토타입 제작 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_7')}
                                        {/* 개발 제안서 작성 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_8')}
                                        {/* 피칭 / 프리젠테이션 */}
                                    </li>
                                </ul>
                            </div>

                            <div className="bottom_line"></div>
                        </li>

                        <li className="right step2">
                            <div className="h_title_box">
                                <strong className="h_title">
                                    {pt('ForStages_9')}
                                    {/* <span className="num">2</span>알파 빌드 단계
                                    <small className="en">Production</small> */}
                                </strong>
                            </div>

                            <div className="step_cont">
                                <p className="text">
                                    {pt('ForStages_10')}
                                    {/* 실제 게임을 구성하는 핵심 기능을 개발하고 완성하는 단계입니다.
                                    <br />
                                    알파 단계의 마무리는 게임성의 확인입니다
                                    <br />
                                    개발한 핵심 기능을 QA와 Fun QA를 통해 확인하면 다음 단계로
                                    의사결정을 보다 명확하게 할 수 있습니다.
                                    <br />
                                    지금이 투바이트를 만나기에 가장 좋은 시기입니다.
                                    <br />
                                    개발 상황과 게임 특징에 따른 최적의 QA 플랜을 알아보세요. */}
                                </p>
                                <ul>
                                    <li>
                                        {pt('ForStages_11')}
                                        {/* 초반 30분이내 분량의 게임 플레이 개발 (vertical slice) */}
                                    </li>
                                    <li>
                                        {pt('ForStages_12')}
                                        {/* 상세 게임기획서 작성 (GDD Game Design Document) */}
                                    </li>
                                    <li>
                                        {pt('ForStages_13')}
                                        {/* 게임 아트워크 제작 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_14')}
                                        {/* 세부 프로젝트 제작 계획 수립 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_15')}
                                        {/* 현지화(로컬라이제이션) 검토 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_16')}
                                        {/* 빌드 테스트 및 평가 */}
                                    </li>
                                </ul>
                            </div>

                            <div className="toggle_box scrtFn">
                                <div className="box_in">
                                    <div className="box_tit">
                                        <strong>
                                            {pt('ForStages_17')}
                                            {/* 바로 지금이, 2bytes를 만나기 적절한 시기 입니다. */}
                                        </strong>
                                    </div>

                                    <div className="cont">
                                        <ul>
                                            <li>
                                                {pt('ForStages_18')}
                                                {/* 개발사 상황과 게임 특징에 따라 어떤 QA를 할지 최적의 QA
                                                Plan을 제안드립니다.
                                                <br />
                                                대형사 내부에서 적용되는 수준의 Test case sheet에 따른
                                                QA로 게임의 퀄리티를 높여보세요. */}
                                            </li>
                                            <li>
                                                {pt('ForStages_19')}
                                                {/* 게임 장르, 타켓 유저별로 글로벌 런칭할 언어 / 국가
                                                최적화를 확인 해보세요. */}
                                            </li>
                                        </ul>

                                        <div className="btn_area">
                                            <Link className="btn_basic" to={`/${i18n.language}/Contact`}>
                                                {pt('ForStages_20')}
                                                {/* 문의/업무의뢰 */}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bottom_line"></div>
                        </li>

                        <li className="left step3">
                            <div className="h_title_box">
                                <strong className="h_title">
                                    {pt('ForStages_21')}
                                    {/* <span className="num">3</span>베타 빌드 단계
                                    <small className="en">Pre-Production</small> */}
                                </strong>
                            </div>

                            <div className="step_cont">
                                <p className="text">
                                    {pt('ForStages_22')}
                                    {/* 게임의 방향성을 가다듬고, 게임 콘텐츠를 구현하는 단계입니다.
                                    <br />
                                    개발 리소스 80% 이상이 투입되는 가장 중요한 시기입니다.
                                    <br />
                                    게임 밸런스를 최적화하는 데 집중하면서 콘텐츠와 기능을
                                    고도화하는 개발 작업이 이루어집니다.
                                    <br />
                                    대형사 수준의 Full QA를 통해 게임의 퀄리티를 높여보세요.
                                    <br />또 글로벌 서비스 계획을 투바이트와 함께 준비할 시기가
                                    바로 지금입니다! */}
                                </p>
                                <ul>
                                    <li>
                                        {pt('ForStages_23')}
                                        {/* 전체 게임플레이 개발 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_24')}
                                        {/* 대단위 컨텐츠 제작 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_25')}
                                        {/* FGT (Focus Group Test) 진행 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_26')}
                                        {/* 밸런스 테스트 진행 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_27')}
                                        {/* (지속적인) QA 진행 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_28')}
                                        {/* CBT 진행 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_29')}
                                        {/* 런칭 준비 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_30')}
                                        {/* 마케팅 플랜 수립 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_31')}
                                        {/* 현지화(로컬라이제이션) 진행 */}
                                    </li>
                                </ul>
                            </div>

                            <div className="toggle_box scrtFn">
                                <div className="box_in">
                                    <div className="box_tit">
                                        <strong>
                                            {pt('ForStages_32')}
                                            {/* 2bytes와 함께 게임별 최적화 QA와 로컬라이제이션을
                                            확인하세요 */}
                                        </strong>
                                    </div>

                                    <div className="cont">
                                        <ul>
                                            <li>
                                                {pt('ForStages_33')}
                                                {/* 대형사 내부에서 적용되는 수준의 Test case sheet에 따른
                                                QA로 게임의 퀄리티를 높여보세요. */}
                                            </li>
                                            <li>
                                                {pt('ForStages_34')}
                                                {/* 비용 절감을 위해, 어느 정도 개발된 이후에 QA를 진행하는
                                                경우가 많습니다. <br />
                                                2bytes와 QA 협의를 하면, 전체 시간 비용을 줄일 수
                                                있습니다.
                                                <br />
                                                출시 타깃 국가별로 FGTesting 이 필요합니다. 지금
                                                알아보세요. */}
                                            </li>
                                            <li>
                                                {pt('ForStages_35')}
                                                {/* 한국어 &gt; 도착 언어로 디렉트 현지화하는 2bytes의 높은
                                                퀄리티를 제안받아보세요. */}
                                            </li>
                                        </ul>

                                        <div className="btn_area">
                                            <Link className="btn_basic" to={`/${i18n.language}/Contact`}>
                                                {pt('ForStages_36')}
                                                {/* 문의/업무의뢰 */}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bottom_line"></div>
                        </li>

                        <Box component="li" className="right step4 last">
                            <div className="h_title_box">
                                <strong className="h_title">
                                    {pt('ForStages_37')}
                                    {/* <span className="num">4</span>라이브 빌드 단계
                                    <small className="en">Post-production</small> */}
                                </strong>
                            </div>

                            <div className="step_cont">
                                <p className="text">
                                    {pt('ForStages_38')}
                                    {/* 게임 출시 전략에 따라 런칭 및 서비스에 들어가는 단계입니다.
                                    <br />
                                    흔히 지표와의 싸움의 기간이라고 합니다.
                                    <br />
                                    각종 지표 분석과 경쟁 게임의 상황을 모니터링하면서 업데이트가
                                    이어집니다.
                                    <br />
                                    서비스 중인 게임의 글로벌 출시나 서비스 국가/언어를 추가한다면
                                    다양한 언어의 이용자와 원활한 커뮤니케이션을 원한다면
                                    투바이트를 찾아주세요. */}
                                </p>
                                <ul>
                                    <li>
                                        {pt('ForStages_39')}
                                        {/* 소프트 런칭 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_40')}
                                        {/* 지표분석 및 업데이트 설계 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_41')}
                                        {/* 빌드 업데이트 및 안정화 작업 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_42')}
                                        {/* 정식 런칭 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_43')}
                                        {/* 매출 분석 및 개선 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_44')}
                                        {/* 업데이트 기획/개발 */}
                                    </li>
                                    <li>
                                        {pt('ForStages_45')}
                                        {/* 해외 서비스 준비 */}
                                    </li>
                                </ul>
                            </div>

                            <div className="toggle_box scrtFn">
                                <div className="box_in">
                                    <div className="box_tit">
                                        <strong>
                                            {pt('ForStages_46')}
                                            {/* 바로 지금이, 2bytes를 만나기 적절한 시기 입니다. */}
                                        </strong>
                                    </div>

                                    <div className="cont">
                                        <ul>
                                            <li>
                                                {pt('ForStages_47')}
                                                {/* 2bytes QA의 전문 분야 중 하나가 스토어 정책과 피쳐
                                                테스트 중심의 QA입니다.
                                                <br />
                                                국가별 런칭 전략에 따른 QA를 확인해보세요. */}
                                            </li>
                                            <li>
                                                {pt('ForStages_48')}
                                                {/* 글로벌 런칭에 따라 다양한 언어의 이용자를 만날 준비
                                                되셨나요?
                                                <br />
                                                2bytes는 글로벌 Player Support를 통해 다국어 이용자
                                                문의에 대응하고, <br />
                                                다양한 언어/문화 특성에 기반하여 개발사와 원활한
                                                커뮤니케이션으로 유저와의 소통을 돕습니다. */}
                                            </li>
                                        </ul>

                                        <div className="btn_area">
                                            <Link className="btn_basic" to={`/${i18n.language}/Contact`}>
                                                {pt('ForStages_49')}
                                                {/* 문의/업무의뢰 */}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Box className="bottom_line" sx={{
                                left: '31.7rem !important',
                                // margin: 'auto',
                                // display: 'none',
                                '@media screen and (max-width: 1320px)': {
                                    marginLeft: 'initial !important',
                                    left: 'calc(40.04% + -28.6rem) !important',
                                }
                            }}></Box>
                        </Box>
                    </ol>
                </div>
            </section>
        </>
    );
}

function ForStages() {
    return (
        <div id="container" className="cont_fg">
            <SectionTop title="4 Stages" mtitle="4 Stages" />
            <Content />
            <Services />
            <SectionBottom />
        </div>
    );
}

export default ForStages;
