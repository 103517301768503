import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ActiveLink from '../components/ActiveLink';

import { getLanguageText } from '../i18n';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function Header({isUseNotify}) {

    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    const mobileResolution = 992;

    const [resolution, setResolution] = useState('');
    const [openMenu, setOpenMenu] = useState('');
    const [mobileOpenMenu, setMobileOpenMenu] = useState('');
    const [openLang, setOpenLang] = useState('');
    const navigate = useNavigate();
    const location = useLocation();


    const changeLang = (lang) => {
        const to = location.pathname.replace(i18n.language, lang);
        navigate(to);
    };


    useEffect(() => {
        window.addEventListener('resize', resizeWindow);
        window.dispatchEvent(new Event('resize'));

        let _wst = $(window).scrollTop();
        $(window)
            .on('scroll', function () {
                let _cst = $(window).scrollTop();

                let _hh = $('#header').outerHeight();
                let _end = $(document).height() - $(window).height();
                let _isEnd = (_cst > _end - 150 || _cst >= _end);
                let _isFloatingEnd = (_cst > _end - 500 || _cst >= _end);

                let _tt_hh = "6.4rem";
                if ($(window).width() <= 992) {

                    if (i18n.language === "ko") {

                        let _nw = $('.notice_wrap').outerHeight();

                        _hh = _hh + _nw;
                        if (_cst === 0) {
                            {// 사용 않함 ?
                                // $('.notice_wrap').css('display', 'flex');
                            }

                            // 공고 노출 여부
                            if (isUseNotify) {
                                // 공고 있을 시 사용   
                                $('.section_top').css('padding-top', '5.6rem');
                                const headerRect = document.querySelector('#header')?.getBoundingClientRect?.();
                                // console.log(headerRect?.bottom);
                                _tt_hh = headerRect?.bottom ?? 134;
                                $('.cont_contact .section_top').css('padding-top', '0');// Contact 별도 적용
                            } else {
                                // 공고 없을 시 사용
                                _tt_hh = "6.4rem";
                            }

                        } else {
                            {// 사용 않함 ?
                                //    $('.notice_wrap').css('display', 'none');
                            }
                            $('.section_top').css('padding-top', '0');

                            _tt_hh = "6.4rem";
                        }
                    }


                    if (_isEnd) {
                        $('.section_top .tab_wrap').css('top', '6.4rem');
                        $('#header').addClass('sticky');
                        $('.section_top .tab_wrap').css('top', 0);
                    } else {

                        if (_wst < _cst) {
                            $('#header').removeClass('sticky');
                            if (
                                $('.section_top .tab_wrap').length &&
                                !$('#header').hasClass('sticky')
                            ) {
                                $('.section_top .tab_wrap').addClass('fixed_top');
                                if (_hh - _cst > 0) {
                                    $('.section_top .tab_wrap').css('top', _hh - _cst);

                                } else {
                                    $('.section_top .tab_wrap').css('top', 0);


                                }
                            }
                        } else if (_cst === 0) {
                            $('#header').removeClass('sticky');

                            $('.section_top .tab_wrap').css('top', _tt_hh);
                        } else if (_cst >= $(document).height() - $(window).height() - 10) {
                            $('#header').removeClass('sticky');
                            $('.section_top .tab_wrap').css('top', 0);
                        } else {
                            $('#header').addClass('sticky');
                            $('.section_top .tab_wrap').css('top', '6.4rem');
                        }

                    }
                    if (_isFloatingEnd) {
                        $('.floating_btn_wrap').css('opacity', 0);
                    } else {
                        $('.floating_btn_wrap').css('opacity', 1);
                    }

                    _wst = _cst;
                } else {
                    if (i18n.language === "ko") {
                        $('.section_top').css('padding-top', '0');
                        $('.section_top .tab_wrap').css('top', 0);
                    }
                }
            })
            .trigger('scroll');

        return () => {
            window.removeEventListener('resize', resizeWindow);
        };
    }, [resolution]);

    const resizeWindow = () => {
        if (window.innerWidth > mobileResolution) {
            setResolution(() => '');
            setMobileOpenMenu(() => '');
            setOpenLang(() => '');
        } else {
            setResolution(() => 'mobile');
        }
    };

    // 2 depth 메뉴 오픈
    const onOpenMenu = (e) => {
        if (resolution !== 'mobile') {
            if (e.type === 'mouseover') {
                setOpenMenu(() => 'open');
            } else if (e.type === 'mouseout') {
                setOpenMenu(() => '');
            }
        }
    };

    // 다국어 안내 팝업 오픈
    const onOpenLang = (e) => {
        if (resolution !== 'mobile') {
            if (e.type === 'mouseover') {
                setOpenLang(() => 'open');
            } else if (e.type === 'mouseout') {
                setOpenLang(() => '');
            }
        }
    };

    // 모바일 2 depth 오픈
    const onMobileOpenMenu = () => {
        if (mobileOpenMenu === 'open') {
            setMobileOpenMenu(() => '');
            setOpenLang(() => '');
        } else if (mobileOpenMenu === '') {
            setMobileOpenMenu(() => 'open');
        }
    };

    // 모바일 2 depth 오픈
    const onMobileCloseMenu = () => {
        setMobileOpenMenu(() => '');
        setOpenLang(() => '');
    };

    // 모바일 다국어 안내 팝업 오픈
    const onOpenLangClick = () => {
        if (resolution === 'mobile') {
            if (openLang === 'open') {
                setOpenLang(() => '');
            } else if (openLang === '') {
                setOpenLang(() => 'open');
            }
        }
    };

    return (
        <header id="header" className={`${openMenu} `}>

            <div className="header_wrap">
                <div className="inner">
                    <h1 className="h_logo">
                        <Link to={``}>2Bytes byte the world</Link>
                    </h1>

                    <div className={`header_nav_wrap ${mobileOpenMenu}`} >
                        <div className="header_menu_wrap">
                            <nav className="nav_gnb">
                                <ul className="nav_list_items">
                                    <li className="nav_list">
                                        <ActiveLink
                                            activeClassName="on"
                                            className="menu_dep1"
                                            to={`Why2Bytes`}
                                            as="Why2Bytes"
                                            onClick={onMobileCloseMenu}
                                        >
                                            {pt('Footer_3')}
                                        </ActiveLink>
                                    </li>
                                    <li
                                        className="nav_list"
                                        onMouseOver={onOpenMenu}
                                        onMouseOut={onOpenMenu}
                                    >
                                        <ActiveLink
                                            className="menu_dep1"
                                            activeClassName="on"
                                            to={`Services/Localization`}
                                            as="Services"
                                            onClick={onMobileCloseMenu}
                                        >
                                            {pt('Footer_14')}
                                        </ActiveLink>
                                        <div className="menu_dep2">
                                            <ul>
                                                <li>
                                                    <ActiveLink
                                                        activeClassName="on"
                                                        to={`Services/Localization`}
                                                        onClick={onMobileCloseMenu}
                                                    >
                                                        {pt('Footer_5')}
                                                    </ActiveLink>
                                                </li>
                                                 <li>
                                                    <ActiveLink
                                                        activeClassName="on"
                                                        to={`Services/PlayerSupport`}
                                                        onClick={onMobileCloseMenu}
                                                    >
                                                        {pt('Footer_7')}
                                                    </ActiveLink>
                                                </li>
                                                <li>
                                                    <ActiveLink
                                                        activeClassName="on"
                                                        to={`Services/ContentMarketing`}
                                                        onClick={onMobileCloseMenu}
                                                    >
                                                        {pt('Footer_15')}
                                                    </ActiveLink>
                                                </li>
                                                <li>
                                                    <ActiveLink
                                                        activeClassName="on"
                                                        to={`Services/QA`}
                                                        onClick={onMobileCloseMenu}
                                                    >
                                                        {pt('Footer_6')}
                                                    </ActiveLink>
                                                </li>
                                                <li>
                                                    <ActiveLink
                                                        activeClassName="on"
                                                        to={`Services/GameSound`}
                                                        onClick={onMobileCloseMenu}
                                                    >
                                                        {pt('Footer_16')}
                                                    </ActiveLink>
                                                </li>
                                                <li>
                                                    <ActiveLink
                                                        activeClassName="on"
                                                        to={`Services/CoPublishing`}
                                                        onClick={onMobileCloseMenu}
                                                    >
                                                        {pt('Footer_17')}
                                                    </ActiveLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="nav_list">
                                        <ActiveLink
                                            activeClassName="on"
                                            className="menu_dep1"
                                            to={`Portfolio`}
                                            onClick={onMobileCloseMenu}
                                        >
                                            {pt('Footer_8')}
                                        </ActiveLink>
                                    </li>
                                    <li className="nav_list">
                                        <a
                                            className="menu_dep1"
                                            target="_blank"
                                            href={i18n.language === "ko" ? "https://medium.com/2bytescorp" : "https://medium.com/bytetheworld"}
                                            rel="noreferrer"
                                        >
                                            {pt('Footer_9')}
                                        </a>
                                    </li>
                                    <li className="nav_list">
                                        <ActiveLink
                                            activeClassName="on"
                                            className="menu_dep1"
                                            to={`Contact`}
                                            onClick={onMobileCloseMenu}
                                        >
                                            {pt('Footer_10')}
                                        </ActiveLink>
                                    </li>
                                    <li
                                        className="nav_list"
                                        onMouseOver={onOpenMenu}
                                        onMouseOut={onOpenMenu}
                                    >
                                        <ActiveLink
                                            className="menu_dep1"
                                            activeClassName="on"
                                            to={`Careers/Life`}
                                            as="Careers"
                                            onClick={onMobileCloseMenu}
                                        >
                                            {pt('Footer_11')}
                                            {/* Careers */}
                                        </ActiveLink>
                                        <div className="menu_dep2">
                                            <ul>
                                                <li>
                                                    <ActiveLink
                                                        activeClassName="on"
                                                        to={`Careers/Life`}
                                                        onClick={onMobileCloseMenu}
                                                    >
                                                        Life at 2bytes
                                                    </ActiveLink>
                                                </li>
                                                <li>
                                                    <ActiveLink
                                                        activeClassName="on"
                                                        to={`Careers/Jobs`}
                                                        onClick={onMobileCloseMenu}
                                                    >
                                                        Jobs at 2bytes
                                                    </ActiveLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div
                            className="lang_area"
                            onMouseOver={onOpenLang}
                            onMouseOut={onOpenLang}
                            onClick={onOpenLangClick}
                        >
                            <div className={`dropdown ${openLang}`}>
                                <span className="btn__">
                                    <button type="button" className="btn_dropdown ko">
                                        <span>{getLanguageText(i18n.language)}</span>
                                    </button>
                                </span>

                                <ul className="dropdown_items" >
                                    <li className={i18n.language === "ko" ? 'selected' : ''}>
                                        <button onClick={() => { changeLang("ko"); }} >
                                            <span className="text ko">한국어</span>
                                            <small>{pt('Header_lang_ko')}</small>
                                        </button>
                                    </li>
                                    <li className={i18n.language === "en" ? 'selected' : ''}>
                                        <button onClick={() => { changeLang("en"); }}>
                                            <span className="text en">ENGLISH</span>
                                            <small>{pt('Header_lang_en')}</small>
                                        </button>
                                    </li>
                                    <li className={i18n.language === "ja" ? 'selected' : ''}>
                                        <button onClick={() => { changeLang("ja"); }}>
                                            <span className="text jpn">日本語</span>
                                            <small>{pt('Header_lang_ja')}</small>
                                        </button>
                                    </li>
                                    <li className={i18n.language === "zh-CN" ? 'selected' : ''}>
                                        <button onClick={() => { changeLang("zh-CN"); }}>
                                            <span className="text ch_01">简体中文</span>
                                            <small>{pt('Header_lang_zh-CN')}</small>
                                        </button>
                                    </li>
                                    {/*
                                    <li className="soon">
                                        <a href='!#' onClick={(e) => { changeLang(e); }}>
                                            <span className="text ch_02">繁體中文</span>
                                            <small>중국어 (번체)</small>
                                        </a>
                                    </li>
                                    <li className="soon">
                                        <a href='!#' onClick={(e) => { changeLang(e); }}>
                                            <span className="text gm">Deutsch</span>
                                            <small>독일어</small>
                                        </a>
                                    </li>
                                    <li className="soon">
                                        <a href='!#' onClick={(e) => { changeLang(e); }}>
                                            <span className="text fr">Français</span>
                                            <small>프랑스어</small>
                                        </a>
                                    </li>
                                    <li className="soon">
                                        <a href='!#' onClick={(e) => { changeLang(e); }}>
                                            <span className="text sp">Español</span>
                                            <small>스페인어</small>
                                        </a>
                                    </li>
                                    */}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <button
                        type="button"
                        className={`btn_open_menu ${mobileOpenMenu}`}
                        onClick={onMobileOpenMenu}
                    >
                        <span>{pt('Header_openMenu')}</span>
                    </button>
                    <div className="dim" onClick={onMobileCloseMenu} />
                </div>
            </div>
        </header>
    );
}
