/** @jsxImportSource @emotion/react */
import React, { useEffect} from 'react';
import SectionTop from '../layout/SectionTop';
import SectionBottom from '../layout/SectionBottom';
import Services from '../components/Services'

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

function CoPublishing(props) {
  const { t, i18n } = useTranslation();
  const pt = (v) => parse(i18n.t(v));

  useEffect(() => {
    $(window).trigger('scroll');
    $(window).scrollTop(0);
  }, []);
  
  return (
    <div id="container" className="cont_cp">
        <SectionTop title="Co-Publishing" mtitle="CP" />
        <section className="section_discript">
            <div className="inner">
                <strong className="title">
                    {pt('CP_step')}
                </strong>

                <div className="step_box type2">
                    <dl>
                        <dt>
                            {pt('CP_step_1_title')}
                        </dt>
                        <dd>
                            {pt('CP_step_1_content')}
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            {pt('CP_step_2_title')}
                        </dt>
                        <dd>
                            {pt('CP_step_2_content')}
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            {pt('CP_step_3_title')}
                        </dt>
                        <dd>
                            {pt('CP_step_3_content')}
                        </dd>
                    </dl>
                </div>

                <div className='bg_img_content'>
                    <strong className='title'>{pt('CP_strategy')}</strong>
                    <div className='left_content type4'>
                        <strong className='title'>{pt('CP_strategy_1_title')}</strong>
                        <p>{pt('CP_strategy_1_content')}</p>
                    </div>
                    <div className='right_content type5'>
                        <strong className='title'>{pt('CP_strategy_2_title')}</strong>
                        <p>{pt('CP_strategy_2_content')}</p>
                    </div>
                    <div className='left_content type6'>
                        <strong className='title'>{pt('CP_strategy_3_title')}</strong>
                        <p>{pt('CP_strategy_3_content')}</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="section_player">
            <div className="text_box">
                <div className="inner d_table">
                    <div className="d_cell text_primary">
                        {pt('CP_contect')}
                    </div>
                    <div className="d_cell btn_area">
                        <a
                            href={`/${i18n.language}/Contact`}
                            className="btn_basic btn_primary btn_xl btn_pop_open"
                        >
                            {pt('QA_11')}
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section className="section_contents">
            <div className="inner">
                <div className="h_title_box">
                    <h3 className="h_title">{pt('CP_process')}</h3>
                </div>

                <div className="cont">
                    <div className="progress_box">
                        <ul className="flex_box">
                            <li>
                                <p className="prog_head">{pt('CP_step1_title')}</p>
                                <div className="prog_body">
                                    <div className="prog_con">
                                        <strong className="tit">{pt('CP_step1_list1_title')}</strong>
                                        <p className="con">{pt('CP_step1_list1_content')}</p>
                                    </div>
                                    <div className="prog_con">
                                        <strong className="tit">{pt('CP_step1_list2_title')}</strong>
                                        <p className="con">{pt('CP_step1_list2_content')}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <p className="prog_head">{pt('CP_step2_title')}</p>
                                <div className="prog_body">
                                    <div className="prog_con">
                                        <strong className="tit">{pt('CP_step2_list1_title')}</strong>
                                        <p className="con">{pt('CP_step2_list1_content')}</p>
                                    </div>
                                    <div className="prog_con">
                                        <strong className="tit">{pt('CP_step2_list2_title')}</strong>
                                        <p className="con">{pt('CP_step2_list2_content')}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <p className="prog_head">{pt('CP_step3_title')}</p>
                                <div className="prog_body">
                                    <div className="prog_con">
                                        <strong className="tit">{pt('CP_step3_list1_title')}</strong>
                                        <p className="con">{pt('CP_step3_list1_content')}</p>
                                    </div>
                                    <div className="prog_con">
                                        <strong className="tit">{pt('CP_step3_list2_title')}</strong>
                                        <p className="con">{pt('CP_step3_list2_content')}</p>
                                    </div>
                                    <div className="prog_con">
                                        <strong className="tit">{pt('CP_step3_list3_title')}</strong>
                                        <p className="con">{pt('CP_step3_list3_content')}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <p className="prog_head">{pt('CP_step4_title')}</p>
                                <div className="prog_body">
                                    <div className="prog_con">
                                        <strong className="tit">{pt('CP_step4_list1_title')}</strong>
                                        <p className="con">{pt('CP_step4_list1_content')}</p>
                                    </div>
                                    <div className="prog_con">
                                        <strong className="tit">{pt('CP_step4_list2_title')}</strong>
                                        <p className="con">{pt('CP_step4_list2_content')}</p>
                                    </div>
                                    <div className="prog_con">
                                        <strong className="tit">{pt('CP_step4_list3_title')}</strong>
                                        <p className="con">{pt('CP_step4_list3_content')}</p>
                                    </div>
                                    <div className="prog_con">
                                        <strong className="tit">{pt('CP_step4_list4_title')}</strong>
                                        <p className="con">{pt('CP_step4_list4_content')}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <Services />
        <SectionBottom />
    </div>
  );
}

export default CoPublishing;
