/** @jsxImportSource @emotion/react */
import {
    useState,
    useEffect,
    useRef,
    memo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { css } from '@mui/system';
import { useTranslation } from 'react-i18next';

import StepName from './StepName';
import StepEnglishName from './StepEnglishName';
import StepNationalityResidenceStatus from './StepNationalityResidenceStatus';
import StepLanguage from './StepLanguage';
import StepEmailPhone from './StepEmailPhone';
import StepPortfolio from './StepPortfolio';
import StepResume from './StepResume';
import StepLastCheck from './StepLastCheck';
import StepFinished from './StepFinished';
import StepCss from './StepCss';

import apiSetS3Careers from '../../../api/setS3Careers';
import apiSetNotionCareers from '../../../api/setNotionCareers';
import setSlackCareers from '../../../api/setSlackCareers';
import setMailCareers from '../../../api/setMailCareers';

function StepForm(props) {
    const cssTalentPool = css`
        background-color: #FFF;
        text-align: center;
        /* margin: 40px 20px; */
        margin-top: 4rem;
        margin-bottom: 11.7rem;
        padding-top: 3.5rem;
        border: 0px 2px 4px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        @media (max-width: 992rem) {
            box-shadow: none;
            margin-bottom: 4rem;
        }
      `;

    return (
        <section css={cssTalentPool}>
            <Poster {...props} />
        </section>
    );
}

function Poster(props) {
    if (props.division === undefined && props.job === undefined) return <></>;

    const infomations = {
        division: {
            title: '찾으시는 공고가 없나요?',
            desc: '인재풀에 지원서를 등록해 주시면 적절한 포지션 발생 시 검토 후 개별적으로 연락하여 지원 의사를 문의드립니다.',
            subtitle: '인재풀 등록하기',
            className: 'talent_pool_division'
        },
        job: {
            title: '투바이트에서 여러분을 모십니다.',
            desc: '투바이트와 함께 로켓 성장하실 분들, 많이 많이 지원해주세요.',
            subtitle: '즉시 지원하기',
            className: 'talent_pool_job'
        }
    };

    const infomation = props.job ? infomations.job : infomations.division;

    // console.log(infomation);

    const cssTitle = css`
        font-size: 2.6rem;
        line-height: 3.6rem;
        padding: 1.6rem;
        color: #444 !important;`;

    const cssDesc = css`
        font-size: 1.8rem;
        line-height: 3rem;
        color: #828282;
        padding-bottom: 4.8rem;
        margin: auto 4rem;
    `;
    const cssLine = css`
        border-bottom: 3px solid #f7f7f7;
    `;
    return (
        <div className="Poster">
            <div className={infomation.className} />
            <div css={cssTitle}>{infomation.title}</div>
            <div css={cssDesc}>{infomation.desc}</div>
            <div css={cssLine} />
            <StepFormContainer {...props} {...{ infomation }} />
        </div>
    );
}

function StepFormContainer(props) {
    const { infomation } = props;
    const { subtitle } = infomation;
    // console.log(props);

    const cssRoot = css`
        height: 55rem;
    `;

    const cssSubtitle = css`
    display: inline-block;
    line-height: 3.6rem;
    text-align: center;
    font-weight: 400;
    margin: 5rem auto;
    color: #743FA3;
    @media (max-width: 1320px) {
        font-size: 2rem;
    }
    @media (max-width: 992rem) {
        font-size: 2rem;
        line-height: 2.8rem;
    }
    
  `;
    const cssSubTittleLine = css`
    height: 8px;
    background-color: #1dd1d3;
    transform: translateY(-1rem);
    z-index: 0;
    mix-blend-mode: multiply;
  `;

    return (
        <div className="StepFormContainer">
            <div css={cssSubtitle}>
                {/* subtitle = 인재풀 등록하기 | 즉시 지원하기 */}
                <div style={{ padding: '0 5px' }}>{subtitle}</div>
                <div css={cssSubTittleLine} />
            </div>
            <StepPatterns {...props} />
        </div>
    );
}

const StepPatterns = function (props) {
    const { t, i18n } = useTranslation();
    // console.log('StepPatterns');
    const [index, setIndex] = useState(0);
    const { division, jobNo, job } = props;
    const [checkList, setCheckList] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [isReset, setIsReset] = useState(false);

    const nameRef = useRef();
    const englishNameRef = useRef();
    const nationalityResidenceStatusRef = useRef();
    const languageRef = useRef();
    const emailPhoneRef = useRef();
    const portfolioRef = useRef();
    const resumeRef = useRef();
    const resumeRef2 = useRef();
    const finishedRef = useRef(false);
    const lastCheckRef = useRef();

    const to = useNavigate();

    if (!division) return <></>;

    // console.log('---', division.부서)

    useEffect(() => {
        // console.log('reset index => 0', division);
        setIndex(0);
    }, [division, job]);

    useEffect(() => {
        if (index === 0) {
            careers?.resetSenderParams();
            careers?.resetCheckList();
            // finishedRef.current = false;
            setIsFinished(false);
            // console.log('Need reset senderParams', careers.senderParams);
        }
        if (pattern?.length > 0) {
            let lastIndex = pattern.length - 1;
            console.log('last index', lastIndex);
            if (index === lastIndex) {
                // finishedRef.current=true
                setIsFinished(true);
            }
        }
    }, [index]);


    const patterns = {
        0: [],
        1: [
            <StepName ref={nameRef} />,
            <StepEmailPhone ref={emailPhoneRef} />,
            <StepResume
                ref={resumeRef}
                title="자기소개서, 경력기술서를 포함한 이력서를 첨부해 주세요."
                placeholder="이력서(pdf 파일) 첨부"

            />,
            <StepLastCheck ref={lastCheckRef} {...{ checkList }} />,
            <StepFinished />
        ],
        2: [
            // 디자이너실
            <StepName ref={nameRef} />,
            <StepEmailPhone ref={emailPhoneRef} />,
            <StepResume
                ref={resumeRef}
                title="자기소개서, 경력기술서를 포함한 이력서를 첨부해 주세요."
                placeholder="이력서(pdf 파일) 첨부"

            />,
            <StepPortfolio ref={portfolioRef} />,
            <StepLastCheck ref={lastCheckRef} {...{ checkList }} />,
            <StepFinished />
        ],
        3: [
            // 글로벌QA팀
            <StepName ref={nameRef} />,
            <StepEmailPhone ref={emailPhoneRef} />,
            <StepResume
                ref={resumeRef}
                title="자기소개서, 경력기술서를 포함한 이력서를 첨부해 주세요."
                placeholder="이력서(pdf 파일) 첨부"

            />,
            <StepPortfolio ref={portfolioRef} skipable />,
            <StepLastCheck ref={lastCheckRef} {...{ checkList }} />,
            <StepFinished />
        ],
        4: [

            //---This is test code---
            // <StepName name="김희동" ref={nameRef} />,
            // <StepLanguage language="한국어 → 중문(간체)" ref={languageRef} languages={job?.langsets} />,
            // <StepEnglishName name="김희동" englishName="KIM HEE DONG" ref={englishNameRef} />,
            // <StepEmailPhone email="ddb1494@2bytescorp.com" phone="01050018893" ref={emailPhoneRef} />,
            // <StepNationalityResidenceStatus nationality="대한민국" residenceStatus="국민" ref={nationalityResidenceStatusRef} />,
            // <StepResume
            //     ref={resumeRef}
            //     title="자기소개서를 첨부해주세요."
            //     desc="*자유 양식으로 한국어와 타겟어로 각각 1,000자 이상 작성하세요."
            //     placeholder="자기소개서 파일(.pdf/.word) 첨부"
            // />,
            // <StepResume2
            //     ref={resumeRef2}
            //     title="경력기술서를 포함한 이력서를 첨부해 주세요."
            //     placeholder="이력서 파일(.pdf) 첨부"
            // />,
            // <StepPortfolio ref={portfolioRef} skipable={true} />,
            // <StepLastCheck ref={lastCheckRef} {...{ checkList }} />,
            // <StepFinished />,
            //---This is test code---

            <StepLanguage ref={languageRef} languages={job?.langsets} />,
            <StepEnglishName ref={englishNameRef} />,
            <StepEmailPhone ref={emailPhoneRef} />,
            <StepNationalityResidenceStatus ref={nationalityResidenceStatusRef} />,
            <StepResume
                key={location.href+'-resume-1'}
                ref={resumeRef}
                title="자기소개서를 첨부해주세요."
                desc="*자유 양식으로 한국어와 타겟어로 각각 1,000자 이상 작성하세요."
                placeholder="자기소개서(pdf 또는 word 파일) 첨부"
            />,
            <StepResume
                key={location.href+'-resume-2'}
                ref={resumeRef2}
                title="경력기술서를 포함한 이력서를 첨부해 주세요."
                placeholder="이력서(pdf 파일) 첨부"
            />,
            <StepLastCheck ref={lastCheckRef} {...{ checkList }} />,
            <StepFinished />
        ]
    };
    const key = division['부서별 즉시 지원 양식'] ?? 0;
    const pattern = patterns[key];
    // console.log(key);
    const onPrev = () => {
        if (index !== 0) {
            setIndex(index - 1);
        }
    };
    const onNext = () => {
        const lastIndex = pattern.length - 1;
        if (index < lastIndex) {
            let e = pattern[index].ref.current;
            if (e?.test() === true) {
                if (e?.isLastCheckStep) {
                    if (isSending === false) {
                        setIsSending(true);
                        onSend().then(() => {
                            setIndex(index + 1);
                            setIsSending(false);
                        }).catch(err => {
                            console.log(err);
                            setIsSending(false);
                        });
                    }
                } else {
                    onRefSave();
                    setIndex(index + 1);
                }
            } else {
                console.log('벨리드 체크하세요', e?.test());
            }
        } else if (index === lastIndex) {
            setIndex(0);
            setIsReset(v => !v);
            careers?.resetSenderParams();
            // careers?.resetCheckList();
        }
    };

    const onRefSave = () => {
        const { checkList, senderParams } = careers;
        careers?.resetSenderParams();
        // careers?.resetCheckList();

        const getValueOfRef = (ref, k) => ref.current?.[k];
        const setCheckListOfRef = (k, v) => {
            // console.log('---', k, v);
            if (v !== undefined) {
                let item = checkList.find(([key]) => key === k);
                if (item) {
                    item[1] = v;
                } else {
                    checkList.push([k, v]);
                }
            }
            // else {
            //     let index = checkList.findIndex(([key]) => key === k);
            //     if (index > 0) {
            //         checkList.splice(index, 1);
            //     }
            // }
        };
        const setSenderParams = (k, v) => {
            if (v !== undefined) {
                senderParams[k] = v;
            }
            // else {
            //     delete senderParams[k];
            // }
        };

        let value;

        value = division?.부서;
        setCheckListOfRef('부서', value);
        setSenderParams('division', value);
        // console.log(value);

        value = division?.dbId;
        setSenderParams('dbId', value);
        // console.log(value);

        value = division?.dataSet;
        setSenderParams('dataSet', value);
        // console.log(value);

        value = job?.props?.공고명;
        setCheckListOfRef('공고명', value);
        setSenderParams('notice', value);
        // console.log(value);

        value = getValueOfRef(languageRef, 'language');
        setCheckListOfRef('언어', value);
        setSenderParams('language', value);
        // console.log(value);

        value = getValueOfRef(englishNameRef, 'allName') ?? getValueOfRef(nameRef, 'name');
        setCheckListOfRef('이름', value);
        setSenderParams('applicantName', value);
        // console.log(value);

        value = getValueOfRef(emailPhoneRef, 'email');
        setCheckListOfRef('이메일', value);
        setSenderParams('email', value);
        // console.log(value);


        value = getValueOfRef(emailPhoneRef, 'phone');
        setCheckListOfRef('전화번호', value);
        setSenderParams('phoneNumber', value);
        // console.log(value);


        value = getValueOfRef(nationalityResidenceStatusRef, 'nationality');
        setCheckListOfRef('국적', value);
        setSenderParams('nationality', value);
        // console.log(value);
        value = getValueOfRef(nationalityResidenceStatusRef, 'residenceStatus');
        setCheckListOfRef('체류자격', value);
        setSenderParams('visa', value);
        // console.log(value);


        value = getValueOfRef(emailPhoneRef, 'phone');
        setCheckListOfRef('전화번호', value);
        setSenderParams('phoneNumber', value);
        // console.log(value);

        value = getValueOfRef(resumeRef, 'filename');
        setCheckListOfRef('이력서', value);
        // console.log(value);
        value = getValueOfRef(resumeRef, 'file');//File
        setSenderParams('myIntroductionFileURL', value);
        // console.log('이력서', value);



        value = getValueOfRef(portfolioRef, 'portfolioFilename');
        setCheckListOfRef('포트폴리오', value);
        console.log('포트폴리오(파일이름)', value);
        value = getValueOfRef(portfolioRef, 'file');//File
        setSenderParams('myPortfolioFileURL', value);
        console.log('포트폴리오(파일)', value);
        value = getValueOfRef(portfolioRef, 'portfolioUrl');
        setCheckListOfRef('포트폴리오url', value);
        setSenderParams('myPortfolioURL', value);
        console.log('포트폴리오url', value);


        if(!senderParams.myPortfolioFileURL) {
            value = getValueOfRef(resumeRef2, 'filename');
            setCheckListOfRef('경력기술서', value);
            value = getValueOfRef(resumeRef2, 'file');//File
            setSenderParams('myPortfolioFileURL', value);
        }

        // console.log(checkList);
        console.log(senderParams);
    };

    // test
    // onNext();

    const onSend = async () => {
        console.log('onSend-Start');
        const { senderParams } = careers;

        if (senderParams.myIntroductionFileURL instanceof File) {
            let file = senderParams.myIntroductionFileURL;
            let awsS3FileUrl = '-';
            if (file) {
                let filename = new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().replace(/[-:]/g, '') + Math.random().toString(36).slice(2) + file.name.replace(/[^.a-z\d]/gi, '');
                // console.log(file, filename);
                awsS3FileUrl = await apiSetS3Careers(file, filename);
                // console.log(awsS3FileUrl);
            }
            senderParams.myIntroductionFileURL = awsS3FileUrl;
        }
        // else{
        //     senderParams.myIntroductionFileURL = '-'
        // }
        if (senderParams.myPortfolioFileURL instanceof File) {
            let file = senderParams.myPortfolioFileURL;
            let awsS3FileUrl = '-';
            if (file) {
                let filename = new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().replace(/[-:]/g, '') + Math.random().toString(36).slice(2) + file.name.replace(/[^.a-z\d]/gi, '');
                // console.log(file, filename);
                awsS3FileUrl = await apiSetS3Careers(file, filename);
                // console.log(awsS3FileUrl);
            }
            senderParams.myPortfolioFileURL = awsS3FileUrl;
        }

        senderParams.languageType = i18n.language;
        console.log(senderParams);




        // 데이터를 보내는 작업. 아래 코드 테스트에서 일시적으로 주석처리한다.
        await apiSetNotionCareers(senderParams);
        await setSlackCareers(senderParams);
        await setMailCareers({ recipient: senderParams.email });
        console.log('onSend-End');
    };

    // ---css---
    const cssMain = css``;
    const cssButtons = css`
    display: flex;
    flex-direction: row;
    justify-content: ${index === 0 || index === pattern.length - 1 ? 'center' : 'space-between'};
    padding: 51px 60px;
    @media (max-width: 1320px) {
        width: 100%;
    }
    @media (max-width: 992px) {
        width: 100%;
        font-size: 1.6rem;
        gap: 1.2rem;
        padding: 34px 20px;
    }
  `;

    const cssFromBox = css`
        padding: 0px 2rem;
    `;

    const cssStepButton = css`
    width: 12.8rem;
    height: 5.6rem;
    background: #743fa3;
    color: #fff;
    border: none;
    font-size: 1.8rem;
    font-weight: 500;
    user-select: none;
    @media (max-width: 1320px) {
        width: 10rem;
    }
    @media (max-width: 992px) {
        width: 100%;
        font-size: 1.6rem;
    }
    /* flex-grow: 1; */
    
  `;
    const cssPrevButton = css`
    color: #743FA3;
    background: #FFFFFF;
    border: 1px solid #743FA3;
    ${index === 0 || index === pattern.length - 1 ? 'display: none;' : ''}
  `;

    //   console.log(pattern)
    // console.log('finished', isFinished);

    return (
        <div css={cssMain} onContextMenu={
            () => {
                console.log(isReset);
                setIsReset(v => !v);
            }
        }>


            {/* {pattern[index]} */}
            {
                isReset
                    ?
                    <div css={cssFromBox} key={location.href + '-1'}>{
                        pattern?.map((vd, i) => {
                            // vd.props.hidden = i!==index
                            // vd.props.hidden = false;
                            // console.log(vd, i, index);
                            return <div key={i} hidden={i !== index}>{vd}</div>;
                        })
                    }</div>
                    :
                    <div css={cssFromBox} key={location.href + '-2'}>{
                        pattern?.map((vd, i) => {
                            // vd.props.hidden = i!==index
                            // vd.props.hidden = false;
                            // console.log(vd, i, index);
                            return <div key={i} hidden={i !== index}>{vd}</div>;
                        })
                    }</div>
            }
            <div css={cssButtons}>
                <button css={[cssStepButton, cssPrevButton]} onClick={onPrev}>
                    이전
                </button>
                <button css={cssStepButton} onClick={onNext}>
                    {isFinished ? '확인' : '다음'}
                </button>
            </div>
        </div>
    );
};




export default StepForm;