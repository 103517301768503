/** @jsxImportSource @emotion/react */
import {
  useState,
  useMemo,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react';
import { css } from '@emotion/react';
import StepCss from './StepCss';

//Reference: www.w3resource.com/javascript/form/email-validation.php

const StepEmailPhone = forwardRef((props, ref) => {
//   console.log(props);
  const [emailValid, setEmailValid] = useState(true);
  const [email, setEmail] = useState(props.email ?? '');
  const [emailError, setEmailError] = useState('');
  const [phoneValid, setPhoneValid] = useState(true);
  const [phone, setPhone] = useState(props.phone ?? '');
  const [phoneError, setPhoneError] = useState('');
  const validateEmail = useCallback(
    (mail) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/.test(mail),
    []
  );
  const testName = useCallback(() => {
    let b = email.length > 0;
    setEmailValid(b);
    setEmailError(b ? '' : '*이메일을 입력하세요.');
    if (b === false) return b;
    b = validateEmail(email);
    setEmailValid(b);
    setEmailError(b ? '' : '*이메일을 정확하게 입력하세요.');
    return b;
  }, [email]);
  const testEnglishName = useCallback(() => {
    let b = phone.length > 0;
    setPhoneValid(b);
    setPhoneError(b ? '' : '*전화번호를 입력하세요.');
    if (b === false) return b;
    b = /^[-+0-9]{7,}$/.test(phone);
    setPhoneValid(b);
    setPhoneError(b ? '' : '*전화번호를 정확하게 입력하세요.');
    return b;
  }, [phone]);
  const valid = useMemo(
    () => emailValid && phoneValid,
    [emailValid, phoneValid]
  );
  const test = useCallback(() => {
    let b1 = testName();
    let b2 = testEnglishName();
    console.log('test', b1 && b2);
    return b1 && b2;
  }, [email, phone]);
  const changeName = useCallback((e) => {
    setEmail(e.target.value);
  }, []);
  const changeEnglishName = useCallback((e) => {
    setPhone(e.target.value);
  }, []);

  useImperativeHandle(ref, () => ({
    email,
    setEmail,
    phone,
    setPhone,
    valid,
    test
  }));

  return (
    <div css={StepCss.root}>
      <div css={StepCss.titleRoot}>
        <div css={StepCss.title}>이메일 주소와 전화번호를 작성하세요.</div>
        <div css={StepCss.titleDesc}></div>
      </div>
      <div css={StepCss.fromBox}>
        <div css={StepCss.content2ea}>
            <div css={StepCss.inputBox}>
            <input
                css={StepCss.input}
                type="text"
                value={email}
                onChange={changeName}
                placeholder="이메일 주소"
            />
            <div css={StepCss.error}>{emailError}</div>
            </div>
            <div css={StepCss.inputBox}>
            <input
                css={StepCss.input}
                type="text"
                value={phone}
                onChange={changeEnglishName}
                placeholder="전화번호"
            />
            <div css={StepCss.error}>{phoneError}</div>
            </div>
        </div>
      </div>
    </div>
  );
});

export default StepEmailPhone;
