import call from './call';

/* 
  POST https://5pqyscpi28.execute-api.ap-northeast-2.amazonaws.com/default/pentapeak-emailService-integration
  Body : {
    "recipient": "받는사람 메일주소",
    "subject": "이메일 제목"
  }
  
  Response: {
    statusCode: 200 || 400
    body: 'Email sent!' || 'Sending failed'
  }
*/
export default function mail({ recipient, subject = '가입해주셔서 감사합니다!' }) {
    return call('https://5pqyscpi28.execute-api.ap-northeast-2.amazonaws.com/default/pentapeak-emailService-integration',
        { recipient, subject });
}
