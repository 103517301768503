import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../resources/images/privacy_arrow_right.svg';
function Privacy() {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));
    //sdf
    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);
    return (

        <div id="container" className="cont_pv" lang='en'>
            <section className="section">
                <div className="inner ">
                    <div className="title-box">
                        <h2>
                            {pt('Privacy_New_1')}
                            {/* 개인정보 처리방침 */}
                            <span> {pt('Privacy_New_2')}</span>
                        </h2>
                        <p>{pt('Privacy_New_3')}</p>
                    </div>
                    <div className="dl_box">
                        <dl>
                            {/* 1 */}
                            <dt>
                                {pt('Privacy_New_4')}
                            </dt>
                            <dd>

                                {pt('Privacy_New_5')}
                            </dd>
                            <dd>
                                <span>{pt('Privacy_New_6')}</span>
                                <span>{pt('Privacy_New_7')}</span>
                            </dd>
                            <dd>
                                <span>{pt('Privacy_New_8')}</span>
                                <span>{pt('Privacy_New_9')}</span>
                            </dd>
                        </dl>
                        <dl>
                            {/* 2 */}
                            <dt>
                                {pt('Privacy_New_10')}
                            </dt>
                            <dd>
                                {pt('Privacy_New_11')}
                            </dd>
                            <dd>
                                <span>{pt('Privacy_New_12')}</span>
                                <span>{pt('Privacy_New_13')}</span>
                            </dd>
                            <dd>
                                <span>{pt('Privacy_New_14')}</span>
                                <span>{pt('Privacy_New_15')}</span>
                                <span>{pt('Privacy_New_16')}</span>
                            </dd>
                        </dl>
                        <dl>
                            {/* 3 */}
                            <dt>
                                {pt('Privacy_New_17')}
                            </dt>
                            <dd>
                                {pt('Privacy_New_18')}
                            </dd>
                        </dl>
                        <dl>
                            {/* 4 */}
                            <dt>
                                {pt('Privacy_New_19')}
                            </dt>
                            <dd >
                                {pt('Privacy_New_20')}
                            </dd>
                            <dd >
                                <span>{pt('Privacy_New_21')}</span>
                                <span >
                                    {pt('Privacy_New_22')}
                                </span>
                            </dd>
                            <dd>
                                <span>{pt('Privacy_New_24')}</span>
                                <span>
                                    {pt('Privacy_New_25')}
                                    <strong className='point'>
                                        {pt('Privacy_New_26')}
                                    </strong>
                                </span>
                                <span>
                                    {pt('Privacy_New_27')}&nbsp;
                                    <strong className='point'>
                                        {pt('Privacy_New_28')}
                                    </strong>
                                    {pt('Privacy_New_29')}
                                </span>
                            </dd>
                        </dl>
                        <dl>
                            {/* 5 */}
                            <dt>
                                {pt('Privacy_New_30')}
                            </dt>
                            <dd>
                                <dd className='mb5'>
                                    {pt('Privacy_New_31')}
                                </dd>
                                <dd className='mb5'>
                                    {pt('Privacy_New_32')}
                                </dd>
                                <dd >
                                    {pt('Privacy_New_33')}
                                </dd>
                                <span>
                                    {pt('Privacy_New_34')}
                                </span>
                                <span>
                                    {pt('Privacy_New_35')}
                                </span>
                            </dd>
                        </dl>
                        <dl>
                            {/* 6 */}
                            <dt>
                                {pt('Privacy_New_36')}
                            </dt>
                            <dd>
                                {pt('Privacy_New_37')}
                            </dd>
                        </dl>
                        <dl>
                            {/* 7 */}
                            <dt>
                                {pt('Privacy_New_38')}
                            </dt>
                            <dd>
                                <span className='fw500'>
                                    {pt('Privacy_New_39')}
                                </span>
                                <span>{pt('Privacy_New_40')}</span>
                                <span>{pt('Privacy_New_41')}</span>
                                <span>{pt('Privacy_New_42')}</span>
                            </dd>
                        </dl>
                        <dl>
                            {/* 8 */}
                            <dt>
                                {pt('Privacy_New_43')}
                            </dt>
                            <dd>
                                {pt('Privacy_New_44')}
                            </dd>
                            <dd>
                                <span className='fw500'>
                                    {pt('Privacy_New_45')}
                                </span>

                                <span>{pt('Privacy_New_46')}</span>
                                <span>{pt('Privacy_New_47')}</span>
                            </dd>
                            <dd>
                                <span className='fw500'>
                                    <p>{pt('Privacy_New_48')}</p>
                                </span>
                                <span>{pt('Privacy_New_49')}</span>
                                <span>{pt('Privacy_New_50')}</span>
                                <span>{pt('Privacy_New_51')}</span>
                            </dd>
                            <dd>
                                <span className='fw500'>
                                    {pt('Privacy_New_52')}
                                </span>
                                <span>{pt('Privacy_New_53')}</span>
                            </dd>
                        </dl>
                        <dl>
                            {/* 9 */}
                            <dt>
                                {pt('Privacy_New_54')}
                            </dt>
                            <dd>
                                <p>{pt('Privacy_New_55')}</p>
                                <p>{pt('Privacy_New_56')}</p>
                            </dd>
                            <dd>
                                <span className='fw500'>{pt('Privacy_New_57')}</span>
                                <span className='flex'><div className='point_area'><div className='point'></div></div> {pt('Privacy_New_58')}</span>
                                <span className='flex'><div className='point_area'><div className='point'></div></div> {pt('Privacy_New_59')}</span>
                                <span className='flex'><div className='point_area'><div className='point'></div></div> {pt('Privacy_New_60')}</span>
                            </dd>
                            <dd>
                                <span className='fw500'>{pt('Privacy_New_61')}</span>
                                <span className='flex'><div className='point_area'><div className='point'></div></div>{pt('Privacy_New_62')}</span>
                                <span className='flex'><div className='point_area'><div className='point'></div></div> {pt('Privacy_New_63')}</span>
                                <span className='flex'><div className='point_area'><div className='point'></div></div> {pt('Privacy_New_64')}</span>
                            </dd>
                            <dd className='mb5'>
                                {pt('Privacy_New_65')}
                            </dd>
                            <dd>
                                <span className='flex'> <div className='point_area'><div className='point'></div></div> {pt('Privacy_New_66')}</span>
                                <span className='flex'><div className='point_area'><div className='point'></div></div>  {pt('Privacy_New_67')}</span>
                                <span className='flex'><div className='point_area'><div className='point'></div></div> {pt('Privacy_New_68')}</span>
                            </dd>
                        </dl>
                        <dl >
                            {/* 10 */}
                            <dt>
                                {pt('Privacy_New_69')}
                            </dt>
                            <dd className='mb5'>
                                {pt('Privacy_New_70')}
                            </dd>
                            <dd>
                                <span>{pt('Privacy_New_71')}</span>
                                <span >{pt('Privacy_New_72')}</span>
                            </dd>
                        </dl>
                        <Link to={`/${i18n.language}/Privacy/20220111`} className='PrivacyChangeButton' >{pt('Privacy_New_73')}<Arrow /></Link>
                    </div>

                </div>
            </section >
        </div >
    );
}

export default Privacy;
