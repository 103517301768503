import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

import { getLanguageText } from '../i18n';

const WithoutTopMenuLayout = () => {

    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    const mobileResolution = 992;

    const [resolution, setResolution] = useState('');
    const [mobileOpenMenu, setMobileOpenMenu] = useState('');
    const [openLang, setOpenLang] = useState('');
    const navigate = useNavigate();

    const changeLang = (lang) => {
        const to = location.pathname.replace(i18n.language, lang);
        navigate(to);
    };

    useEffect(() => {
        window.addEventListener('resize', resizeWindow);
        window.dispatchEvent(new Event('resize'));

        var _wst = $(window).scrollTop();
        $(window)
            .on('scroll', function () {
                var _cst = $(window).scrollTop();
                var _hh = $('#header').outerHeight();
                var _end = $(document).height() - $(window).height();
                var _isEnd = (_cst > _end - 150 || _cst >= _end);
                var _isFloatingEnd = (_cst > _end - 500 || _cst >= _end);
                if ($(window).width() <= 992) {
                    if (_isEnd) {
                        $('.section_top .tab_wrap').css('top', '6.4rem');
                        $('#header').addClass('sticky');
                        $('.section_top .tab_wrap').css('top', 0);


                    } else {

                        if (_wst < _cst) {
                            $('#header').removeClass('sticky');
                            if (
                                $('.section_top .tab_wrap').length &&
                                !$('#header').hasClass('sticky')
                            ) {
                                $('.section_top .tab_wrap').addClass('fixed_top');
                                if (_hh - _cst > 0) {
                                    $('.section_top .tab_wrap').css('top', _hh - _cst);
                                } else {
                                    $('.section_top .tab_wrap').css('top', 0);
                                }
                            }
                        } else if (_cst === 0) {
                            $('#header').removeClass('sticky');
                            $('.section_top .tab_wrap').css('top', '6.4rem');
                        } else if (_cst >= $(document).height() - $(window).height() - 10) {
                            $('#header').removeClass('sticky');
                            $('.section_top .tab_wrap').css('top', 0);
                        } else {
                            $('#header').addClass('sticky');
                            $('.section_top .tab_wrap').css('top', '6.4rem');
                        }
                    }
                    if (_isFloatingEnd) {
                        $('.floating_btn_wrap').css('opacity', 0);
                    } else {
                        $('.floating_btn_wrap').css('opacity', 1);
                    }

                    _wst = _cst;
                }
            })
            .trigger('scroll');
        return () => {
            window.removeEventListener('resize', resizeWindow);
        };
    }, [resolution]);

    const resizeWindow = () => {
        if (window.innerWidth > mobileResolution) {
            setResolution(() => '');
            setMobileOpenMenu(() => '');
            setOpenLang(() => '');
        } else {
            setResolution(() => 'mobile');
        }
    };

    // 2 depth 메뉴 오픈
    const onOpenMenu = (e) => {
        if (resolution !== 'mobile') {
            if (e.type === 'mouseover') {
                setOpenMenu(() => 'open');
            } else if (e.type === 'mouseout') {
                setOpenMenu(() => '');
            }
        }
    };

    // 다국어 안내 팝업 오픈
    const onOpenLang = (e) => {
        if (resolution !== 'mobile') {
            if (e.type === 'mouseover') {
                setOpenLang(() => 'open');
            } else if (e.type === 'mouseout') {
                setOpenLang(() => '');
            }
        }
    };

    // 모바일 2 depth 오픈
    const onMobileOpenMenu = () => {
        if (mobileOpenMenu === 'open') {
            setMobileOpenMenu(() => '');
            setOpenLang(() => '');
        } else if (mobileOpenMenu === '') {
            setMobileOpenMenu(() => 'open');
        }
    };

    // 모바일 다국어 안내 팝업 오픈
    const onOpenLangClick = () => {
        if (resolution === 'mobile') {
            if (openLang === 'open') {
                setOpenLang(() => '');
            } else if (openLang === '') {
                setOpenLang(() => 'open');
            }
        }
    };

    return (
        <div id="wrap" >
            <header id="header" className={`$openMenu boder-bottom-1`} >
                <div className="header_wrap">
                    <div className="inner">
                        <h1 className="h_logo">
                            <Link to={`/`}>2Bytes byte the world</Link>
                        </h1>
                        <div className={`header_nav_wrap ${mobileOpenMenu}`} lang="ko">
                            <div
                                className="lang_area"
                                onMouseOver={onOpenLang}
                                onMouseOut={onOpenLang}
                                onClick={onOpenLangClick}
                            >
                                <div className={`dropdown ${openLang}`}>
                                    <span className="btn__">
                                        <button type="button" className="btn_dropdown ">
                                            <span>{getLanguageText(i18n.language)}</span>
                                        </button>
                                    </span>

                                    <ul className="dropdown_items" >
                                        <li className={i18n.language === "ko" ? 'selected' : ''}>
                                            <button onClick={() => { changeLang("ko"); }} >
                                                <span className="text ko">한국어</span>
                                                <small>{pt('Header_lang_ko')}</small>
                                            </button>
                                        </li>
                                        <li className={i18n.language === "en" ? 'selected' : ''}>
                                            <button onClick={() => { changeLang("en"); }}>
                                                <span className="text en">ENGLISH</span>
                                                <small>{pt('Header_lang_en')}</small>
                                            </button>
                                        </li>
                                        <li className={i18n.language === "ja" ? 'selected' : ''}>
                                            <button onClick={() => { changeLang("ja"); }}>
                                                <span className="text jpn">日本語</span>
                                                <small>{pt('Header_lang_ja')}</small>
                                            </button>
                                        </li>
                                        <li className={i18n.language === "zh-CN" ? 'selected' : ''}>
                                            <button onClick={() => { changeLang("zh-CN"); }}>
                                                <span className="text ch_01">简体中文</span>
                                                <small>{pt('Header_lang_zh-CN')}</small>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            className={`btn_open_menu ${mobileOpenMenu}`}
                            onClick={onMobileOpenMenu}
                        >
                            <span>{pt('Header_openMenu')}</span>
                        </button>

                    </div>
                </div>
            </header>
            <Outlet />
            <footer id="footer">
                <div className="f_bottom boder-0">
                    <div className="inner clear">
                        <p className="f_left copyright">
                            {pt('Footer_12')}
                            <br />
                            <Link to={`Privacy`} className="privacyBtn" target="_blank">
                                {pt('Footer_13')}
                            </Link>
                        </p>

                        <div className="f_right sns">
                            <a
                                className="facebook"
                                target="_blank"
                                href="https://www.facebook.com/2bytescorp"
                                rel="noreferrer"
                            >
                                <i className="facebook" />
                            </a>
                            <a
                                className="youtube"
                                target="_blank"
                                href="https://www.youtube.com/channel/UCmK0q-ApIEUODuyS1h-owdg"
                                rel="noreferrer"
                            >
                                <i className="youtube" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer >
        </div >

    );
};

export default WithoutTopMenuLayout;